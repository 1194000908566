import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Fab,
    FormControl,
    InputLabel,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {getLegalNoticesAction} from "../../api/settings";
import {setAllPaginatedLegalNotices, setLoadLegalNoticesTaskStatus} from "../../features/notices/legalNotice";
import {AddNoticeLegalCasePopup} from "../legal-case-actions/legal-case-actions";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";
import {getLegalCaseAction} from "../../api/legalCase";
import {DateRangePicker} from "../../components/DateRangePicker";
import {getPresentableLegalNoticeStatus, LegalNoticeDTO, LegalNoticeStatus} from "../../api/dtos";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import NoticeAction from "../../actions/NoticeAction";
import {DeleteNoticeDialog} from "../delete-notice/deleteNoticeDialog";
import {ChangeNoticeStatusDialog} from "../create-status/changeNoticeStatusDialog";

moment.locale("tr");

export interface LegalCaseNoticesProps {
    id: number;
}

export const LegalCaseNotices: React.FC<LegalCaseNoticesProps> = ({id}) => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const dispatch: any = useDispatch();
    const legalCase = useSelector((state: AppState) => state.legalCase.legalCase);
    const page = Number(searchParams.get("page") || "1");
    const allPaginatedLegalNotices = useSelector(
        (state: AppState) => state.notices.allPaginatedLegalNotices);
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
    const pageRef = useRef(page);
    const [textFilter, setTextFilter] = useState("");
    const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
    const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);
    const [statusFilter, setStatusFilter] = useState<LegalNoticeStatus | null>(null);
    const statusOptions = Object.values(LegalNoticeStatus);
    const [activeNotice, setActiveNotice] = useState<LegalNoticeDTO | null>(null);

    const handleDeleteNoticeDialogOpen = () => setDeleteNoticeDialogOpen(true);
    const [deleteNoticeDialogOpen, setDeleteNoticeDialogOpen] = useState(false);
    const handleDeleteNoticeDialogClose = () => setDeleteNoticeDialogOpen(false);

    const handleChangeStatusDialogOpen = () => setChangeStatusDialogOpen(true);
    const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false);
    const handleChangeStatusDialogClose = () => setChangeStatusDialogOpen(false);

    useEffect(() => {
        dispatch(getLegalCaseAction(id));
        dispatch(getLegalNoticesAction({
                legalCaseId: id,
                textFilter,
                startDateFilter,
                endDateFilter,
                statusFilter,
                page,
            })
        );
        return () => {
            dispatch(setAllPaginatedLegalNotices(null));
            dispatch(setLoadLegalNoticesTaskStatus(null));
        };
    }, []);

    useEffect(() => {
        if (page === 1) {
            dispatch(getLegalNoticesAction({
                    legalCaseId: id,
                    textFilter,
                    startDateFilter,
                    statusFilter,
                    endDateFilter,
                    page,
                })
            );
        } else {
            navigate("?page=1");
        }
    }, [textFilter, startDateFilter, endDateFilter, statusFilter]);

    useEffect(() => {
        if (pageRef.current !== page) {
            dispatch(getLegalNoticesAction({legalCaseId: id, page}));
        }
        pageRef.current = page;
    }, [page]);

    const reloadFirstPage = () => {
        if (page === 1) {
            dispatch(getLegalNoticesAction({
                page,
                textFilter,
                startDateFilter,
                endDateFilter,
                statusFilter,
                userIdFilter: currentUser?.id
            }));
        } else {
            navigate("");
        }
    }

    const handleNoticeAdded = () => {
        if (page === 1) {
            dispatch(getLegalNoticesAction({legalCaseId: id, page: 1}));
        } else {
            navigate("?page=1");
        }
    };

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const applyTextFilter = (e: { target: { value: any } }) => {
        const inputTextSearch = e.target.value;
        setTextFilter(inputTextSearch);
    };

    const applyStatusFilter = (e: { target: { value: string; }; }) => {
        const selectedStatus = e.target.value === "" ? null : e.target.value as LegalNoticeStatus;
        setStatusFilter(selectedStatus);
    };

    const handleClearStatus = () => setStatusFilter(null);

    const applyStartDateFilter = (date: Date | null) => {
        setStartDateFilter(date);
    };

    const applyEndDateFilter = (date: Date | null) => {
        setEndDateFilter(date);
    };
    if (!legalCase) {
        return null;
    }
    const openChangeStatus = (notice: LegalNoticeDTO) => {
        setActiveNotice(notice);
        handleChangeStatusDialogOpen();
    };

    const openDelete = (notice: LegalNoticeDTO) => {
        setActiveNotice(notice);
        handleDeleteNoticeDialogOpen();
    };

    const handleDelete = () => {
        setActiveNotice(null);
        handleDeleteNoticeDialogClose();
        reloadFirstPage();
    }

    const handleChangeStatus = () => {
        setActiveNotice(null);
        handleChangeStatusDialogClose();
        reloadFirstPage();
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                }}>
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{marginLeft: "auto", marginBottom: 5}}
                    onClick={() => setPopupOpen(true)}>
                    <AddIcon/>
                </Fab>
            </Box>

            {deleteNoticeDialogOpen && activeNotice != null && (
                <DeleteNoticeDialog
                    notice={activeNotice}
                    handleClose={handleDeleteNoticeDialogClose}
                    handleDelete={handleDelete}/>
            )}

            {changeStatusDialogOpen && activeNotice != null && (
                <ChangeNoticeStatusDialog
                    notice={activeNotice}
                    handleChangeStatus={handleChangeStatus}
                    handleClose={handleChangeStatusDialogClose}/>
            )}

            <Paper elevation={3} sx={{p: 3}}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="legal notices table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: "25%"}}>
                                    <TextField
                                        margin="dense"
                                        style={{minWidth: "210px"}}
                                        label="Tebligat Başlığı"
                                        variant="outlined"
                                        size="small"
                                        onChange={applyTextFilter}/>
                                </TableCell>
                                <TableCell style={{width: "25%"}}/>
                                <TableCell style={{width: "25%"}}/>
                                <TableCell>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        adapterLocale={tr}>
                                        <DateRangePicker
                                            startDate={startDateFilter}
                                            endDate={endDateFilter}
                                            applyStartDateFilter={applyStartDateFilter}
                                            applyEndDateFilter={applyEndDateFilter}/>
                                    </LocalizationProvider>
                                </TableCell>
                                <TableCell style={{width: "25%"}}/>
                                <TableCell style={{width: "16%"}}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="statusFilter">Durum</InputLabel>
                                        <Select
                                            labelId="statusFilter"
                                            value={statusFilter || ""}
                                            onChange={applyStatusFilter}
                                            label="Durumu"
                                            defaultValue=""
                                            endAdornment={
                                                statusFilter && (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClearStatus}>
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }>
                                            {statusOptions.map((status) => (
                                                <MenuItem key={status} value={status}>
                                                    {getPresentableLegalNoticeStatus(status)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell style={{width: "25%"}}/>
                            </TableRow>
                            <TableRow>
                                <TableCell>TEBLİGAT BAŞLIĞI</TableCell>
                                <TableCell>AÇIKLAMA</TableCell>
                                <TableCell>ATANAN KULLANICILAR</TableCell>
                                <TableCell>TEBLİĞ TARİHİ</TableCell>
                                <TableCell>SON TARİH</TableCell>
                                <TableCell>DURUMU</TableCell>
                                <TableCell>İŞLEMLER</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allPaginatedLegalNotices?.entity.map((notice, index) => (
                                <TableRow key={index}>
                                    <TableCell>{notice.title}</TableCell>
                                    <TableCell>{notice.description}</TableCell>
                                    <TableCell>
                                        {notice.assignees.length > 0
                                            ? notice.assignees.map((user) => user.firstName + " " + user.lastName).join(', ')
                                            : 'Atama Yok'}
                                    </TableCell>
                                    <TableCell>
                                        {notice.arrivalDate
                                            ? moment.unix(notice.arrivalDate).format("DD MMMM YYYY")
                                            : "-"}
                                    </TableCell>
                                    <TableCell>
                                        {notice.dueDate
                                            ? moment.unix(notice.dueDate).format("DD MMMM YYYY")
                                            : "-"}
                                    </TableCell>
                                    <TableCell>{getPresentableLegalNoticeStatus(notice.status)}</TableCell>
                                    <TableCell>
                                        <NoticeAction
                                            notice={notice}
                                            onChangeStatus={openChangeStatus}
                                            onDelete={openDelete}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {allPaginatedLegalNotices?.totalNumberPages != null &&
                    allPaginatedLegalNotices.totalNumberPages > 1 && (
                        <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                            <Pagination
                                page={page}
                                count={allPaginatedLegalNotices.totalNumberPages}
                                defaultPage={1}
                                onChange={handleChangePage}/>
                        </div>
                    )}
                {isPopupOpen && (
                    <AddNoticeLegalCasePopup
                        legalCase={legalCase}
                        onClose={() => setPopupOpen(false)}
                        onNoticeAdded={handleNoticeAdded}/>
                )}
            </Paper>
        </>
    );
};
