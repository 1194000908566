import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {Delete, Edit} from "@mui/icons-material";
import {LegalNoticeDTO} from "../api/dtos";

interface NoticeActionProps {
    notice: LegalNoticeDTO;
    onChangeStatus: (notice: LegalNoticeDTO) => void;
    onDelete: (notice: LegalNoticeDTO) => void;
}

const NoticeAction: React.FC<NoticeActionProps> = ({
                                                       notice,
                                                       onChangeStatus,
                                                       onDelete
                                                   }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    const handleChangeStatus = () => handleMenuItemClick(() => onChangeStatus(notice));

    const handleDeleteNotice = () => handleMenuItemClick(() => onDelete(notice));

    return (
        <>
            <IconButton
                aria-controls={open ? 'notice-action-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                size='small' aria-label='settings' className='card-more-options'
                sx={{color: 'text.secondary'}}>
                <DotsVertical/>
            </IconButton>
            <Menu
                id="notice-action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={handleChangeStatus}>
                    <IconButton size='small' aria-label='edit' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <Edit/>
                    </IconButton>
                    Durumunu Değiştir
                </MenuItem>
                <MenuItem onClick={handleDeleteNotice}>
                    <IconButton size='small' aria-label='delete' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <Delete/>
                    </IconButton>
                    Tebligatı Sil
                </MenuItem>
            </Menu>
        </>
    );
}

export default NoticeAction;
