import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import UserLayout from "../components/UserLayout";
import {CreateTaskDialog} from "./create-task/createTaskDialog";
import {getAllPaginatedTasksAction} from "../api/task";
import {AppState} from "../store";
import {setAllPaginatedTasks} from "../features/task/taskSlice";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {
    CaseType,
    getPresentableTaskPriority,
    getPresentableTaskStatus,
    LegalCaseDTO,
    MinimalLegalCaseDTO,
    TaskDTO,
    TaskPriority,
    TaskStatus
} from "../api/dtos";
import {ChangeStatusDialog} from "./create-status/changeStatusDialog";
import {Pagination} from "@mui/lab";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import TaskAction from "../actions/TaskAction";
import * as XLSX from "xlsx-js-style";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {robotoRegularBase64} from '../shared/pdf-fonts';
import {DeleteTaskDialog} from "./delete-task/deleteTaskDialog";
import moment from "moment/moment";
import {EditTaskDialog} from "./edit-task/editTaskDialog";
import {AsyncTaskStatusType} from "../shared/dtos";

export default function Task() {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const location = useLocation();
    const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState(false);
    const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false);
    const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] = useState(false);
    const [activeTask, setActiveTask] = useState<TaskDTO | null>(null);
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");
    const allPaginatedTasks = useSelector((state: AppState) => state.task.allPaginatedTasks);
    const [textFilter, setTextFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState<TaskStatus | null>(null);
    const [priorityFilter, setPriorityFilter] = useState<TaskPriority | null>(null);
    const pageRef = useRef(page);
    const textFilterRef = useRef(textFilter);
    const statusFilterRef = useRef(statusFilter);
    const priorityFilterRef = useRef(priorityFilter);
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const getAllPaginatedTasksStatus = useSelector((state: AppState) => state.task.getAllPaginatedTasksStatus)

    const loadTasks = useCallback(() => {
        dispatch(getAllPaginatedTasksAction({
            legalCaseIdFilter: null,
            userIdFilter: null,
            companyIdFilter: currentUser?.associatedCompany?.id || null,
            textFilter,
            statusFilter,
            priorityFilter,
            page
        }));
    }, [page, textFilter, statusFilter, priorityFilter, dispatch]);

    useEffect(() => {
        loadTasks();
        return () => {
            dispatch(setAllPaginatedTasks(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (pageRef.current !== page ||
            textFilterRef.current !== textFilter ||
            statusFilterRef.current !== statusFilter ||
            priorityFilterRef.current !== priorityFilter) {
            loadTasks();
            pageRef.current = page;
            textFilterRef.current = textFilter;
            statusFilterRef.current = statusFilter;
            priorityFilterRef.current = priorityFilter;
        }
    }, [page, textFilter, statusFilter, priorityFilter, dispatch]);

    const handleCreateTaskDialogOpen = () => setCreateTaskDialogOpen(true);
    const handleCreateTaskDialogClose = () => setCreateTaskDialogOpen(false);

    const handleChangeStatusDialogOpen = () => setChangeStatusDialogOpen(true);
    const handleChangeStatusDialogClose = () => setChangeStatusDialogOpen(false);

    const handleDeleteTaskDialogOpen = () => setDeleteTaskDialogOpen(true);
    const handleDeleteTaskDialogClose = () => setDeleteTaskDialogOpen(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const reloadFirstPage = () => {
        if (page === 1) {
            dispatch(getAllPaginatedTasksAction({
                legalCaseIdFilter: null,
                userIdFilter: null,
                companyIdFilter: currentUser?.associatedCompany?.id || null,
                textFilter,
                statusFilter,
                priorityFilter,
                page
            }));
        } else {
            navigate("/gorevler");
        }
    }

    const handleCreateTask = () => reloadFirstPage();

    const openCreateTask = () => handleCreateTaskDialogOpen();

    const handleEditTask = () => {
        handleEditDialogClose();
        reloadFirstPage();
    };

    const handleEditDialogOpen = (task: TaskDTO) => {
        setActiveTask(task);
        setEditDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setActiveTask(null);
        setEditDialogOpen(false);
    };

    const openChangeStatus = (task: TaskDTO) => {
        setActiveTask(task);
        handleChangeStatusDialogOpen();
    };

    const openDelete = (task: TaskDTO) => {
        setActiveTask(task);
        handleDeleteTaskDialogOpen();
    };

    const handleChangeStatus = () => {
        setActiveTask(null);
        handleChangeStatusDialogClose();
        reloadFirstPage();
    };

    const handleDelete = () => {
        setActiveTask(null);
        handleDeleteTaskDialogClose();
        reloadFirstPage();
    }

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const applyStatusFilter = (e: { target: { value: string; }; }) => {
        const status = e.target.value === "" ? null : (e.target.value as TaskStatus);
        setStatusFilter(status);
    };

    const applyTextFilter = (e: { target: { value: any; }; }) => {
        const inputTextSearch = e.target.value;
        setTextFilter(inputTextSearch);
    };

    const applyPriorityFilter = (e: { target: { value: string; }; }) => {
        const priority = e.target.value === "" ? null : (e.target.value as TaskPriority);
        setPriorityFilter(priority);
    };

    const handleClearPriority = () => {
        setPriorityFilter(null);
    };

    const handleClearStatus = () => {
        setStatusFilter(null);
    };

    const statusOptions = Object.values(TaskStatus);
    const priorityOptions = Object.values(TaskPriority);

    const getCaseDetailUrl = (legalCase: LegalCaseDTO | MinimalLegalCaseDTO) => {
        switch (legalCase.type) {
            case CaseType.Law:
                return `/hukuk-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.Investigation:
                return `/sorusturmalar/${legalCase.id}/ayrintilar`;
            case CaseType.Criminal:
                return `/ceza-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.Executive:
                return `/icra-takibi/${legalCase.id}/ayrintilar`;
            default:
                return `/hukuk-davalari/${legalCase.id}/ayrintilar`;
        }
    };

    const exportToExcel = () => {
        const headers = [
            ["GÖREV ADI", "AÇIKLAMA", "ATANANAN KULLANICILAR", "DURUM", "İLGİLİ DAVA", "ATANMA TARİHİ", "HEDEFLENEN TARİH", "ÖNCELİK"]
        ];
        const worksheet = XLSX.utils.json_to_sheet(
            (allPaginatedTasks?.entity || []).map((task) => ({
                "Görev Adı": task.title,
                "Açıklama": task.description,
                "Atanan Kullanıcılar": task.assignees.map((user: any) => `${user.firstName} ${user.lastName}`).join(", "),
                "Durum": getPresentableTaskStatus(task.status),
                "İlgili Dava": task.legalCase ? task.legalCase.name : "-",
                "Atanma Tarihi": task.createdAt
                    ? moment.unix(task.createdAt).format("DD MMMM YYYY")
                    : "-",
                "HEDEFLENEN TARİH": task.dueDate
                    ? moment.unix(task.dueDate).format("DD MMMM YYYY")
                    : "-",
                "Öncelik": getPresentableTaskPriority(task.priority),
            }))
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(worksheet, headers);

        const headerRow = headers[0];
        const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
        worksheet['!cols'] = columnWidths;
        headerRow.forEach((header, index) => {
            const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
            if (worksheet[cellRef]) {
                worksheet[cellRef].s = {
                    font: {bold: true,},
                    fill: {
                        patternType: "solid",
                        fgColor: {rgb: "D3D3D3"}
                    }
                };
            }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "GÖREVLER");
        XLSX.writeFile(workbook, "görevler.xlsx");
    };

    const exportToPDF = () => {
        const doc = new jsPDF();

        doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularBase64);
        doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
        doc.setFont('Roboto');

        doc.setFontSize(18);
        doc.text('GÖREVLER', 14, 20);

        const tableData = (allPaginatedTasks?.entity || []).map((task) => [
            task.title,
            task.description,
            task.assignees.map((user: any) => `${user.firstName} ${user.lastName}`).join(", "),
            getPresentableTaskStatus(task.status),
            task.legalCase ? task.legalCase.name : "-",
            task.createdAt
                ? moment.unix(task.createdAt).format("DD MMMM YYYY")
                : "-",
            task.dueDate
                ? moment.unix(task.dueDate).format("DD MMMM YYYY")
                : "-",
            getPresentableTaskPriority(task.priority),
        ]);

        const tableColumns = ['GÖREV ADI', 'AÇIKLAMA', 'ATANAN KULLANICILAR', 'DURUM', 'İLGİLİ DAVA', 'ATANMA TARİHİ', 'HEDEFLENEN TARİH', 'ÖNCELİK'];

        autoTable(doc, {
            head: [tableColumns],
            body: tableData,
            startY: 30,
            margin: {horizontal: 10},
            styles: {
                font: 'Roboto',
                cellPadding: 3,
                fontSize: 10,
                valign: 'middle',
                halign: 'left'
            }
        });

        doc.save('gorevler.pdf');
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>GÖREVLER</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            Excel'e Dışa Aktar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportToPDF}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            PDF'e Dışa Aktar
                        </Button>
                    </Box>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={openCreateTask}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>

            {editDialogOpen && activeTask != null && (
                <EditTaskDialog
                    task={activeTask}
                    handleClose={handleEditDialogClose}
                    handleEditTask={handleEditTask}/>
            )}

            {createTaskDialogOpen && (
                <CreateTaskDialog
                    handleClose={handleCreateTaskDialogClose}
                    handleCreateTask={handleCreateTask}/>
            )}

            {changeStatusDialogOpen && activeTask != null && (
                <ChangeStatusDialog
                    task={activeTask}
                    handleClose={handleChangeStatusDialogClose}
                    handleChangeStatus={handleChangeStatus}/>
            )}

            {deleteTaskDialogOpen && activeTask != null && (
                <DeleteTaskDialog
                    task={activeTask}
                    handleClose={handleDeleteTaskDialogClose}
                    handleDelete={handleDelete}/>
            )}

            <Table sx={{minWidth: 800}} aria-label="task table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: "12%"}}>
                            <TextField
                                margin="dense"
                                style={{minWidth: "210px"}}
                                label="Görev Adı"
                                variant="outlined"
                                size="small"
                                onChange={applyTextFilter}/>
                        </TableCell>
                        <TableCell style={{width: "12%"}}/>
                        <TableCell style={{width: "12%"}}/>
                        <TableCell style={{width: "12%"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="status-label">Durumu</InputLabel>
                                <Select
                                    style={{minWidth: "210px"}}
                                    labelId="status-label"
                                    label="Durumu"
                                    value={statusFilter || ""}
                                    onChange={applyStatusFilter}
                                    defaultValue=""
                                    endAdornment={
                                        statusFilter && (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClearStatus}>
                                                    <ClearIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }>
                                    {statusOptions.map((status) => (
                                        <MenuItem key={status} value={status}>
                                            {getPresentableTaskStatus(status)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell style={{width: "12%"}}/>
                        <TableCell style={{width: "12%"}}/>
                        <TableCell style={{width: "12%"}}/>
                        <TableCell style={{width: "12%"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="priority-label">Öncelik</InputLabel>
                                <Select
                                    style={{minWidth: "210px"}}
                                    labelId="priority-label"
                                    label="Öncelik"
                                    value={priorityFilter || ""}
                                    onChange={applyPriorityFilter}
                                    defaultValue=""
                                    endAdornment={
                                        priorityFilter && (
                                            <InputAdornment position="start" style={{marginRight: "10px"}}>
                                                <IconButton onClick={handleClearPriority}>
                                                    <ClearIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }>
                                    {priorityOptions.map((priority) => (
                                        <MenuItem key={priority} value={priority}>
                                            {getPresentableTaskPriority(priority)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell style={{width: "12%"}}/>
                    </TableRow>
                    <TableRow>
                        <TableCell>GÖREV ADI</TableCell>
                        <TableCell>AÇIKLAMA</TableCell>
                        <TableCell>ATANAN KULLANICILAR</TableCell>
                        <TableCell>DURUMU</TableCell>
                        <TableCell>İLGİLİ DAVA</TableCell>
                        <TableCell>ATANMA TARİHİ</TableCell>
                        <TableCell>HEDEFLENEN TARİH</TableCell>
                        <TableCell>ÖNCELİK</TableCell>
                        <TableCell>İŞLEMLER</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allPaginatedTasks?.entity.map((task) => (
                        <TableRow key={task.id}>
                            <TableCell>{task.title}</TableCell>
                            <TableCell>{task.description}</TableCell>
                            <TableCell>
                                {task.assignees.length > 0
                                    ? task.assignees.map((user: any) => user.firstName + " " + user.lastName).join(', ')
                                    : 'Atama Yok'}
                            </TableCell>
                            <TableCell>{getPresentableTaskStatus(task.status)}</TableCell>
                            <TableCell>
                                {task.legalCase
                                    ? (
                                        <Link to={getCaseDetailUrl(task.legalCase)} className="custom-link">
                                            {task.legalCase.name}
                                        </Link>
                                    )
                                    : "-"
                                }
                            </TableCell>
                            <TableCell>
                                {task.createdAt
                                    ? moment.unix(task.createdAt).format("DD MMMM YYYY")
                                    : "-"}
                            </TableCell>
                            <TableCell>
                                {task.dueDate
                                    ? moment.unix(task.dueDate).format("DD MMMM YYYY")
                                    : "-"}
                            </TableCell>
                            <TableCell>{getPresentableTaskPriority(task.priority)}</TableCell>
                            <TableCell>
                                <TaskAction
                                    onEditTask={() => handleEditDialogOpen(task)}
                                    task={task}
                                    onChangeStatus={openChangeStatus}
                                    onDelete={openDelete}/>
                            </TableCell>
                        </TableRow>
                    ))}
                    {getAllPaginatedTasksStatus?.type !== AsyncTaskStatusType.Loading && allPaginatedTasks?.numberOfElements === 0 && (
                        <TableRow>
                            <TableCell colSpan={9} style={{textAlign: "center"}}>
                                Görevler Bulunamadı
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {allPaginatedTasks?.totalNumberPages != null && allPaginatedTasks.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedTasks.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
}
