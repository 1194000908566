import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import ErrorPage from "./error-page";
import Profile from "./routes/Profile";
import Login from "./routes/Login";
import {PrivateRoutes} from "./routes/PrivateRoutes";
import PageNotFound from "./routes/PageNotFound";
import Home from "./routes/Home";
import {Provider} from "react-redux";
import store from './store';
import Logout from "./routes/Logout";
import Branches from "./routes/Branches";
import {Settings as AppSettings, SettingsConsumer, SettingsProvider} from "./theme/@core/context/settingsContext";
import ThemeComponent from "./theme/@core/theme/ThemeComponent";
import Clients from "./routes/Clients";
import {getUserDataAction} from "./api/auth";
import Settings from "./routes/Settings";
import Tasks from "./routes/Tasks";
import Users from "./routes/Users";
import Cities from "./routes/Cities";
import {getAppToken} from "./shared/utils";
import {LegalCases} from "./routes/LegalCases";
import LegalCaseDetailsTabs from "./routes/legal-case-details/LegalCaseDetailsTabs";
import Task from "./routes/Task";
import {CourtHouses} from "./routes/CourtHouses";
import {Courts} from "./routes/Courts";
import MailSettings from "./routes/MailSettings";
import {MyTasks} from "./routes/MyTasks";
import LegalNotices from "./routes/LegalNotices";
import CompanyLegalCases from "./routes/CompanyLegalCases";
import {MyNotices} from "./routes/MyNotices";
import Allies from "./routes/Allies";
import UserStatistic from "./components/UserStatistic";
import CaseStatusStatistic from "./components/CaseStatusStatistic";
import {Contracts} from "./routes/Contracts";

const router = createBrowserRouter([
    {
        path: "/",
        element: <PrivateRoutes/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                element: <Home/>
            },
            {
                path: "profile",
                element: <Profile/>,
            },
            {
                path: "hukuk-davalari",
                element: <LegalCases/>,
            },
            {
                path: "hukuk-davalari/:id/:tab",
                element: <LegalCaseDetailsTabs/>,
            },
            {
                path: "sorusturmalar",
                element: <LegalCases/>,
            },
            {
                path: "sorusturmalar/:id/:tab",
                element: <LegalCaseDetailsTabs/>,
            },
            {
                path: "ceza-davalari",
                element: <LegalCases/>,
            },
            {
                path: "ceza-davalari/:id/:tab",
                element: <LegalCaseDetailsTabs/>,
            },
            {
                path: "icra-takibi",
                element: <LegalCases/>,
            },
            {
                path: "icra-takibi/:id/:tab",
                element: <LegalCaseDetailsTabs/>,
            },
            {
                path: "settings",
                element: <Settings/>,
            },
            {
                path: "gosterge-paneli/gorevler",
                element: <Tasks/>,
            }
            ,
            {
                path: "kullanicilar",
                element: <Users/>,
            },
            {
                path: "sehirler",
                element: <Cities/>,
            },
            {
                path: "diger-sirketler",
                element: <Clients/>,
            },
            {
                path: "adliyeler",
                element: <CourtHouses/>,
            },
            {
                path: "fenerbahce-subeler",
                element: <Branches/>,
            },
            {
                path: "fenerbahce-istirakler",
                element: <Allies/>,
            },
            {
                path: "gorevler",
                element: <Task/>,
            },
            {
                path: "mahkemeler",
                element: <Courts/>,
            },
            {
                path: "mail-ayarlari",
                element: <MailSettings/>,
            },
            {
                path: "gorevlerim",
                element: <MyTasks/>
            },
            {
                path: "tebligatlar",
                element: <LegalNotices/>
            },
            {
                path: "sirket-bazli-davalar",
                element: <CompanyLegalCases/>
            },
            {
                path: "tebligatlarim",
                element: <MyNotices/>
            },
            {
                path: '/gosterge-paneli/kullanici-istatistikleri',
                element: <UserStatistic/>
            },
            {
                path: '/gosterge-paneli/dava-durumlari',
                element: <CaseStatusStatistic/>
            },
            {
                path: "gorevler",
                element: <Task/>,
            },
            {
                path: "sozlesmeler",
                element: <Contracts/>,
            }
        ]
    },
    {
        path: '/login',
        element: <Login/>
    },
    {
        path: "/logout",
        element: <Logout/>,
    },
    {
        path: "*",
        element: <PageNotFound/>
    }
]);

const initializeApp = async () => {
    try {
        const token = await getAppToken(store.dispatch, store.getState);
        if (token) {
            await store.dispatch(getUserDataAction());
        }
    } catch (error) {
        console.error("Initialization error: ", error);
    }
};

const renderApp = () => {
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
        .render(
            <SettingsProvider>
                <SettingsConsumer>
                    {({settings}) => (
                        <>
                            <ThemeComponent settings={settings}>
                                <Provider store={store}>
                                    <RouterProvider router={router}/>
                                </Provider>
                            </ThemeComponent>
                            <SaveThemeToLocalStorage settings={settings}/>
                        </>
                    )}
                </SettingsConsumer>
            </SettingsProvider>
        );
};

const SaveThemeToLocalStorage = ({settings}: { settings: AppSettings }) => {
    useEffect(() => {
        const savedTheme = localStorage.getItem('hukuk-app-theme');
        if (!savedTheme || settings.mode !== savedTheme) {
            localStorage.setItem("hukuk-app-theme", settings.mode)
        }
    }, [settings.mode]);

    return null;
};

initializeApp().then(() => {
    renderApp();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
