import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {Edit, Lock} from "@mui/icons-material";
import {UserDTO} from "../api/dtos";
import {useSelector} from "react-redux";
import {AppState} from "../store";

interface UserActionProps {
    user: UserDTO;
    onEdit: (user: UserDTO) => void;
    onChangePassword: (user: UserDTO) => void;
}

const UserAction: React.FC<UserActionProps> = ({
                                                   user,
                                                   onEdit,
                                                   onChangePassword
                                               }) => {
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    const handleEditUser = () => handleMenuItemClick(() => onEdit(user));

    const handleResetPassword = () => handleMenuItemClick(() => onChangePassword(user));

    return (
        <div>
            <IconButton
                aria-controls={open ? 'user-action-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                size='small' aria-label='settings' className='card-more-options'
                sx={{color: 'text.secondary'}}>
                <DotsVertical/>
            </IconButton>
            <Menu
                id="user-action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={handleEditUser}>
                    <IconButton size='small' aria-label='edit' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <Edit/>
                    </IconButton>
                    Kullanıcı Düzenle
                </MenuItem>
                {currentUser?.user?.id !== user.id && (
                    <MenuItem onClick={handleResetPassword}>
                        <IconButton size='small' aria-label='change-password' className='card-more-options'
                                    sx={{color: 'text.secondary'}}>
                            <Lock/>
                        </IconButton>
                        Şifre Güncelle
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}

export default UserAction;
