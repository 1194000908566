import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import moment from "moment";
import CustomDialog from "../../components/CustomDialog";
import DatePicker from "react-datepicker";
import DatePickerWrapper from "../../theme/@core/styles/libs/react-datepicker";

interface AddTrialDateDialogProps {
    handleClose: () => void;
    addTrialDate: (result: any) => void;
}

interface FormData {
    date: Date;
}

export const AddTrialDateDialog: React.FC<AddTrialDateDialogProps> = ({handleClose, addTrialDate}) => {

    const handleDateChange = (name: any) => (newDate: any) => {
        setFormData({
            ...formData,
            [name]: newDate
        });
    };

    const [formData, setFormData] = useState<FormData>({
        date: new Date()
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        addTrialDate(moment(formData.date).format("YYYY-MM-DDTHH:mm:ss"));
        handleClose();
    };

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}>
            <DialogTitle>Duruşma Tarihi Seç</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <DatePickerWrapper>
                        <DatePicker
                            selected={formData.date}
                            onChange={handleDateChange('date')}
                            dateFormat="yyyy-MM-dd"
                            locale="tr"
                            inline={true}

                            showMonthDropdown
                            showYearDropdown/>
                    </DatePickerWrapper>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Ekle</Button>
            </DialogActions>
        </CustomDialog>
    );
}
