import AccountCogOutline from 'mdi-material-ui/AccountCogOutline'
import {VerticalNavItemsType} from '../theme/@core/layouts/types'
import BalanceIcon from '@mui/icons-material/Balance';
import GavelIcon from '@mui/icons-material/Gavel';
import TaskIcon from '@mui/icons-material/Task';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {CompanyType, UserDTO, UserRole} from "../api/dtos";

const navigation = (user: UserDTO | null): VerticalNavItemsType => {
    const associatedCompanyType = user?.associatedCompany?.type;
    const isBranchUser = associatedCompanyType === CompanyType.Branch;
    const isSubsidiaryUser = associatedCompanyType === CompanyType.Subsidiary;
    const isAdminOrManager = user && [UserRole.Admin, UserRole.Manager].includes(user.role) || false;

    return [
        {
            sectionTitle: isBranchUser ? 'Şube Paneli' : 'İştirak Paneli',
            visible: isBranchUser || isSubsidiaryUser
        },
        {
            title: 'Görevler',
            icon: GavelIcon as any,
            path: '/gorevler',
            visible: isSubsidiaryUser || isBranchUser
        },
        {
            title: 'Sözleşmeler',
            icon: GavelIcon as any,
            path: '/sozlesmeler',
            visible: isSubsidiaryUser || isBranchUser
        },
        {
            sectionTitle: 'Gösterge Paneli',
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Davalar',
            icon: GavelIcon as any,
            path: '/',
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Kullanıcı İstatistikleri',
            icon: GavelIcon as any,
            path: '/gosterge-paneli/kullanici-istatistikleri',
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Dava Durumları',
            icon: GavelIcon as any,
            path: '/gosterge-paneli/dava-durumlari',
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Görevler',
            icon: TaskIcon as any,
            path: '/gosterge-paneli/gorevler',
            visible: false
        },
        {
            sectionTitle: 'Davalar',
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Şirket Bazlı Davalar',
            icon: BalanceIcon as any,
            path: '/sirket-bazli-davalar',
            openInNewTab: true,
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Hukuk Davaları',
            icon: BalanceIcon as any,
            path: '/hukuk-davalari',
            openInNewTab: true,
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Soruşturmalar',
            icon: BalanceIcon,
            path: '/sorusturmalar',
            openInNewTab: true,
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Ceza Davaları',
            icon: BalanceIcon,
            path: '/ceza-davalari',
            openInNewTab: true,
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'İcra Takipleri',
            icon: BalanceIcon,
            path: '/icra-takibi',
            openInNewTab: true,
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            sectionTitle: 'Görevler',
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Görevler',
            icon: TaskAltIcon,
            path: '/gorevler',
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Görevlerim',
            icon: TaskAltIcon,
            path: '/gorevlerim',
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Tebligatlar',
            icon: TaskAltIcon,
            path: '/tebligatlar',
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            title: 'Tebligatlarım',
            icon: TaskAltIcon,
            path: '/tebligatlarim',
            visible: !isBranchUser && !isSubsidiaryUser
        },
        {
            sectionTitle: 'Müşavirlik',
            visible: false
        },
        {
            title: 'Masraflar',
            icon: CurrencyLiraIcon,
            path: '',
            visible: false
        },
        {
            sectionTitle: 'Ayarlar',
            visible: !isBranchUser && !isSubsidiaryUser && isAdminOrManager
        },
        {
            title: 'Kullanıcılar',
            icon: AccountCogOutline,
            path: '/kullanicilar',
            visible: !isBranchUser && !isSubsidiaryUser && isAdminOrManager
        },
        {
            title: 'E-Posta Ayarları',
            icon: AlternateEmailIcon,
            path: '/mail-ayarlari',
            visible: !isBranchUser && !isSubsidiaryUser && isAdminOrManager
        },
        {
            title: 'Fenerbahçe Şubeler',
            icon: BusinessIcon,
            path: '/fenerbahce-subeler',
            visible: !isBranchUser && !isSubsidiaryUser && isAdminOrManager
        },
        {
            title: 'Fenerbahçe İştirakler',
            icon: BusinessIcon,
            path: '/fenerbahce-istirakler',
            visible: !isBranchUser && !isSubsidiaryUser && isAdminOrManager
        },
        {
            title: 'Diğer Şirketler',
            icon: BusinessIcon,
            path: '/diger-sirketler',
            visible: !isBranchUser && !isSubsidiaryUser && isAdminOrManager
        },
        {
            title: 'Adliyeler',
            icon: AccountBalanceIcon,
            path: '/adliyeler',
            visible: !isBranchUser && !isSubsidiaryUser && isAdminOrManager
        },
        {
            title: 'Mahkeme/Savcılık/İcra Dairesi',
            icon: AccountBalanceIcon,
            path: '/mahkemeler',
            visible: !isBranchUser && !isSubsidiaryUser && isAdminOrManager
        },
        {
            title: 'Şehirler',
            icon: MyLocationIcon,
            path: '/sehirler',
            visible: !isBranchUser && !isSubsidiaryUser && isAdminOrManager
        }
    ]
}

export default navigation
