export enum LitigantType {
    Company = 'Company',
    Client = 'Client',
    FreeText = 'FreeText'
}

export const getPresentableLitigantType = (litigantType: LitigantType) => {
    switch (litigantType) {
        case LitigantType.Client:
            return "Diğer Şirketler";
        case LitigantType.Company:
            return "FB Şirketi";
        case LitigantType.FreeText:
            return "Diğer";
    }
}
