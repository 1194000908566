import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {Pagination} from "@mui/material";
import {AppState} from "../store";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getAllPaginatedCitiesAction} from "../api/settings";
import TextField from "@mui/material/TextField";

export default function Cities() {
    const dispatch: any = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");
    const [filterByName, setFilterByName] = useState("");

    useEffect(() => {
        dispatch(getAllPaginatedCitiesAction({page, filterByName}));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllPaginatedCitiesAction({page, filterByName}));
    }, [page,filterByName]);

    const allPaginatedCities = useSelector((state: AppState) => state.settings.allPaginatedCities);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const applyCitiesFilter = (e: { target: { value: any; }; }) => {
        const inputTextSearch = e.target.value;
        setFilterByName(inputTextSearch);
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>ŞEHİRLER</h1>
                <Table sx={{minWidth: 800}} aria-label='city table'>
                    <TableHead>
                        <TableCell/>
                        <TableCell style={{width: "12%"}}>
                            <TextField
                                margin="dense"
                                style={{minWidth: "210px"}}
                                label="Şehir Adı"
                                variant="outlined"
                                size="small"
                                onChange={applyCitiesFilter}/>
                        </TableCell>
                        <TableRow>
                            <TableCell style={{width: "50%"}}>PLAKA NUMARASI</TableCell>
                            <TableCell style={{width: "50%"}}>ADI</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allPaginatedCities?.entity?.map((city) => (
                            <TableRow key={city.id}>
                                <TableCell>{city.id}</TableCell>
                                <TableCell>{city.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {allPaginatedCities?.totalNumberPages != null && allPaginatedCities.totalNumberPages > 1 && (
                    <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                        <Pagination
                            page={page}
                            count={allPaginatedCities.totalNumberPages}
                            defaultPage={1}
                            onChange={handleChangePage}/>
                    </div>
                )}
            </div>
        </UserLayout>
    );
}
