import React, {useEffect, useState} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from '@mui/material';
import {getPresentableLegalNoticeStatus, LegalNoticeDTO, LegalNoticeStatus} from "../../api/dtos";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {TextFieldVariants} from "@mui/material/TextField/TextField";
import CustomDialog from "../../components/CustomDialog";
import {setUpdateNoticeStatusTaskStatus} from "../../features/notices/legalNotice";
import {updateLegalNoticeStatusAction} from "../../api/settings";

const fieldVariant = "outlined";

interface StatusDialogProps {
    handleClose: () => void;
    handleChangeStatus: () => void;
    notice: LegalNoticeDTO;
}

export const ChangeNoticeStatusDialog: React.FC<StatusDialogProps> = ({
                                                                          notice,
                                                                          handleClose,
                                                                          handleChangeStatus
                                                                      }) => {
    const updateNoticeStatusTaskStatus = useSelector((state: AppState) => state.notices.updateNoticeStatusTaskStatus);
    const [formData, setFormData] = useState<{
        newStatus: LegalNoticeStatus;
    }>({
        newStatus: notice.status
    });

    const [errors, setErrors] = useState<FieldErrors>({});
    const dispatch: any = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setUpdateNoticeStatusTaskStatus(null));
        };
    }, []);

    useEffect(() => {
        if (updateNoticeStatusTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            updateNoticeStatusTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [updateNoticeStatusTaskStatus]);

    const handleSaveClick = async () => {
        if (formData.newStatus !== notice.status) {
            try {
                await dispatch(updateLegalNoticeStatusAction({
                    noticeId: notice.id,
                    newStatus: formData.newStatus
                }));
                handleChangeStatus();
            } catch (error) {
                console.error("Güncelleme işlemi başarısız oldu: ", (error as any).message || error);
            }
        }
    };

    const handleChangeFormData = (event: any) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogTitle>Durumu Değiştir</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="new-status-label">Durum Seç</InputLabel>
                    <Select
                        labelId="new-status-label"
                        id="newStatus"
                        name="newStatus"
                        label="Durum Seç"
                        fullWidth
                        variant={fieldVariant as TextFieldVariants}
                        value={formData.newStatus}
                        onChange={handleChangeFormData}>
                        {Object.values(LegalNoticeStatus).map((statusValue) => (
                            <MenuItem key={statusValue} value={statusValue}>
                                {getPresentableLegalNoticeStatus(statusValue)}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.newStatus && (
                        <ErrorText message={errors.newStatus}/>
                    )}
                </FormControl>
                {updateNoticeStatusTaskStatus?.type === AsyncTaskStatusType.Error && updateNoticeStatusTaskStatus.errorMessage && (
                    <ErrorText type="form" message={updateNoticeStatusTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button onClick={handleSaveClick} color="primary"
                        disabled={formData.newStatus === notice.status}>
                    Kaydet
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
