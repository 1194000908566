import React, {useEffect} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {LegalNoticeDTO} from "../../api/dtos";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {AsyncTaskStatusType} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import CustomDialog from "../../components/CustomDialog";
import {setDeleteNoticeTaskStatus} from "../../features/notices/legalNotice";
import {deleteLegalNoticeAction} from "../../api/settings";

interface DeleteNoticeDialogProps {
    handleClose: () => void;
    handleDelete: () => void;
    notice: LegalNoticeDTO;
}

export const DeleteNoticeDialog: React.FC<DeleteNoticeDialogProps> = ({notice, handleClose, handleDelete}) => {
    const deleteNoticeTaskStatus = useSelector((state: AppState) => state.notices.deleteNoticeTaskStatus);
    const dispatch: any = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setDeleteNoticeTaskStatus(null));
        };
    }, []);

    const handleDeleteClick = async () => {
        try {
            const result = await dispatch(deleteLegalNoticeAction(notice.id));
            if (result.type === 'notice/deleteLegalNotice/fulfilled') {
                handleDelete();
            }
        } catch (error) {
            console.error("Tebligat silme işleminde bir hata oluştu:", error);
        }
    };

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogTitle>Tebligat Sil ({notice.title})</DialogTitle>
            <DialogContent>
                Seçilen Tebligatı Silmek İstiyor musunuz?
                {deleteNoticeTaskStatus?.type === AsyncTaskStatusType.Error && deleteNoticeTaskStatus.errorMessage && (
                    <ErrorText type="form" message={deleteNoticeTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button onClick={handleDeleteClick} color="error">Sil</Button>
            </DialogActions>
        </CustomDialog>
    );
};
