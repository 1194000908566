import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import Box from "@mui/material/Box";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {CreateLegalCaseDialog} from "./create-legal-case/createLegalCaseDialog";
import {AppState} from "../store";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getLegalCasesAction} from "../api/legalCase";
import {Pagination} from "@mui/lab";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {CaseType, getPresentableLegalCaseStatus, LegalCaseDTO, LegalCaseStatus, UserRole} from "../api/dtos";
import moment from "moment";
import {setAllPaginatedLegalCases} from "../features/legal-case/legalCaseSlice";
import {LitigantType} from "../api/litigantType";
import TextField from "@mui/material/TextField";
import LegalCaseAction from "../actions/LegalCaseAction";
import {
    AddNoteLegalCasePopup,
    AddNoticeLegalCasePopup,
    ChangeStatusLegalCasePopup,
    DeleteLegalCasePopup,
    EditLegalCasePopup
} from "./legal-case-actions/legal-case-actions";
import 'moment/locale/tr';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {AddFileToLegalCaseDialog} from "./add-file-to-legalcase/add-file-to-legalcase";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import * as XLSX from "xlsx-js-style";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {robotoRegularBase64} from '../shared/pdf-fonts';
import Button from "@mui/material/Button";
import {AsyncTaskStatusType} from "../shared/dtos";

moment.locale('tr');

export const LegalCases = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let caseType = CaseType.Law;
    let title = "Hukuk Davaları";

    switch (location.pathname) {
        case '/sorusturmalar':
            caseType = CaseType.Investigation;
            title = "Soruşturmalar"
            break;
        case '/ceza-davalari':
            caseType = CaseType.Criminal;
            title = "Ceza Davaları"
            break;
        case '/icra-takibi':
            caseType = CaseType.Executive;
            title = "İcra Takibi"
            break;
    }
    const [searchParams] = useSearchParams();

    const dispatch: any = useDispatch();
    const page = Number(searchParams.get('page') || "1");
    const [nameFilter, setNameFilter] = useState("");
    const [courtIdFilter, setCourtIdFilter] = useState<number | null>(null);
    const [fileNumberFilter, setFileNumberFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState<LegalCaseStatus | null>(null);
    const [courtHouseFilter, setCourtHouseFilter] = useState("");
    const statusOptions = Object.values(LegalCaseStatus);
    const pageRef = useRef(page);
    const caseTypeRef = useRef(caseType);
    const nameFilterRef = useRef(nameFilter);
    const courtIdFilterRef = useRef(courtIdFilter);
    const fileNumberFilterRef = useRef(fileNumberFilter);
    const statusFilterRef = useRef(statusFilter);
    const courtHouseFilterRef = useRef(courtHouseFilter);
    const user = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const loadLegalCasesTaskStatus = useSelector((state: AppState) => state.legalCase.loadLegalCasesTaskStatus)

    const loadLegalCases = useCallback(() => {
        const isAdminOrManager = user && [UserRole.Admin, UserRole.Manager].includes(user.role) || false;
        dispatch(getLegalCasesAction({
            nameFilter,
            typeFilter: caseType,
            courtIdFilter,
            statusFilter,
            fileNumberFilter,
            responsibleIdFilter: isAdminOrManager
                ? null
                : user?.id,
            page
        }));
    }, [page, caseType, nameFilter, courtIdFilter, statusFilter, fileNumberFilter, courtHouseFilter, dispatch]);

    useEffect(() => {
        loadLegalCases();
        return () => {
            dispatch(setAllPaginatedLegalCases(null));
        }
    }, []);

    useEffect(() => {
        if (pageRef.current !== page ||
            caseTypeRef.current !== caseType ||
            nameFilterRef.current !== nameFilter ||
            courtIdFilterRef.current !== courtIdFilter ||
            fileNumberFilterRef.current !== fileNumberFilter ||
            statusFilterRef.current !== statusFilter ||
            courtHouseFilterRef.current !== courtHouseFilter) {
            loadLegalCases();
            pageRef.current = page;
            caseTypeRef.current = caseType;
            nameFilterRef.current = nameFilter;
            courtIdFilterRef.current = courtIdFilter;
            fileNumberFilterRef.current = fileNumberFilter;
            statusFilterRef.current = statusFilter;
            courtHouseFilterRef.current = courtHouseFilter;
        }
    }, [page, caseType, nameFilter, courtIdFilter, fileNumberFilter, statusFilter, courtHouseFilter]);

    useEffect(() => {
        setNameFilter("");
        setCourtIdFilter(null);
        setFileNumberFilter("");
        setStatusFilter(null);
        setCourtHouseFilter("");
    }, [location.pathname]);

    const [createLegalCaseDialogOpen, setCreateLegalCaseDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [addNoteDialogOpen, setAddNoteDialogOpen] = useState(false);
    const [addFileDialogOpen, setAddFileDialogOpen] = useState(false);
    const [addNoticeDialogOpen, setAddNoticeDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false);
    const [addTaskDialogOpen, setAddTaskDialogOpen] = useState(false);
    const [activeLegalCase, setActiveLegalCase] = useState<LegalCaseDTO | null>(null);

    const allPaginatedLegalCases = useSelector((state: AppState) => state.legalCase.allPaginatedLegalCases);

    const handleCreateLegalCaseDialogOpen = () => setCreateLegalCaseDialogOpen(true);
    const handleCreateLegalCaseDialogClose = () => setCreateLegalCaseDialogOpen(false);

    const handleEditLegalCaseDialogOpen = () => setEditDialogOpen(true);
    const handleEditLegalCaseDialogClose = () => setEditDialogOpen(false);

    const handleAddNoteDialogOpen = () => setAddNoteDialogOpen(true);
    const handleAddNoteDialogClose = () => setAddNoteDialogOpen(false);

    const handleAddFileDialogOpen = () => setAddFileDialogOpen(true);
    const handleAddFileDialogClose = () => setAddFileDialogOpen(false);

    const handleAddNoticeDialogOpen = () => setAddNoticeDialogOpen(true);
    const handleAddNoticeDialogClose = () => setAddNoticeDialogOpen(false);

    const handleDeleteLegalCaseDialogOpen = () => setDeleteDialogOpen(true);
    const handleDeleteLegalCaseDialogClose = () => setDeleteDialogOpen(false);

    const handleChangeStatusDialogOpen = () => setChangeStatusDialogOpen(true);
    const handleChangeStatusDialogClose = () => setChangeStatusDialogOpen(false);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };
    let prosecutorsLabel = "DAVACI";
    let reportedEntitiesVisible = true;
    let defendantsLabel = "DAVALI";
    let courtLabel = "MAHKEME";
    let fileNumberLabel = "DOSYA NUMARASI";
    let caseDateLabel = "DAVA TARİHİ";
    let courtHouseLabel = "ADLİYE";
    let courtHouseVisible = true;
    let investigationDateVisible = false;
    let investigationDateLabel = "SORUŞTURMA TARİHİ"
    let statementDateVisible = false;
    let statementDateLabel = "İFADE TARİHİ";
    let followTypeLabel = "TAKİP TÜRÜ";
    let followTypeVisible = false;
    let courtVisible = true;

    switch (caseType) {
        case CaseType.Criminal:// CEZA DAVALARI
            prosecutorsLabel = "MÜŞTEKİ/KATILAN";
            reportedEntitiesVisible = false;
            defendantsLabel = "SANIK";
            courtHouseVisible = true;
            followTypeVisible = false
            courtHouseLabel = "ADLİYE"
            courtLabel = "MAHKEME";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "SUÇ TARİHİ";
            statementDateVisible = false;
            investigationDateVisible = false;
            break;
        case CaseType.Executive:// İCRA TAKİBİ
            prosecutorsLabel = "ALACAKLI";
            reportedEntitiesVisible = false;
            courtHouseVisible = true;
            defendantsLabel = "BORÇLU";
            investigationDateVisible = false
            followTypeVisible = false;
            statementDateVisible = false;
            courtLabel = "İCRA DAİRESİ";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "TAKİP TARİHİ";
            courtVisible = true;
            break;
        case CaseType.Investigation:// SORUŞTURMALAR
            prosecutorsLabel = "MÜŞTEKİ";
            reportedEntitiesVisible = false;
            courtHouseVisible = true;
            defendantsLabel = "ŞÜPHELİ";
            investigationDateVisible = false;
            followTypeVisible = false;
            courtLabel = "SAVCILIK";
            statementDateVisible = false;
            fileNumberLabel = "SORUŞTURMA NUMARASI";
            caseDateLabel = "SUÇ TARİHİ";
            courtVisible = true;
            break;
    }

    const reloadFirstPage = () => {
        if (page === 1) {
            dispatch(getLegalCasesAction({
                nameFilter,
                typeFilter: caseType,
                courtIdFilter,
                statusFilter,
                fileNumberFilter,
                responsibleIdFilter: user?.id || null,
                page
            }));
        } else {
            navigate("");
        }
    };

    const applyNameFilter = (e: { target: { value: any; }; }) => {
        const inputNameSearch = e.target.value;
        setNameFilter(inputNameSearch)
    };

    const applyFileNumberFilter = (e: { target: { value: string; }; }) => {
        setFileNumberFilter(e.target.value);
    };

    const applyStatusFilter = (e: { target: { value: string; }; }) => {
        const status = e.target.value === "" ? null : (e.target.value as LegalCaseStatus)
        setStatusFilter(status);
    };

    const openEdit = (legalCase: LegalCaseDTO) => {
        setActiveLegalCase(legalCase);
        setEditDialogOpen(true);
    };

    /*const openAddNote = (legalCase: LegalCaseDTO) => {
        setActiveLegalCase(legalCase);
        setAddNoteDialogOpen(true);
    };*/

    const openAddFile = (legalCase: LegalCaseDTO) => {
        setActiveLegalCase(legalCase);
        setAddFileDialogOpen(true);
    };

    const openAddNotice = (legalCase: LegalCaseDTO) => {
        setActiveLegalCase(legalCase);
        setAddNoticeDialogOpen(true);
    };

    /*const openDelete = (legalCase: LegalCaseDTO) => {
         setActiveLegalCase(legalCase);
         setDeleteDialogOpen(true);
     };*/

    const openAddTask = (legalCase: LegalCaseDTO) => {
        setActiveLegalCase(legalCase);
        setAddTaskDialogOpen(true);
    };

    const openChangeStatus = (legalCase: LegalCaseDTO) => {
        setActiveLegalCase(legalCase);
        setChangeStatusDialogOpen(true);
    };

    const handleAdd = () => {
        setActiveLegalCase(null);
        handleCreateLegalCaseDialogClose();
    }

    const handleEdit = () => {
        setEditDialogOpen(false);
    };

    const handleDelete = () => {
        setActiveLegalCase(null);
        handleDeleteLegalCaseDialogClose();
    }

    const handleAddNote = () => {
        setActiveLegalCase(null);
        handleAddNoteDialogClose();
    };

    const handleFileAdded = () => {
        setActiveLegalCase(null);
        handleAddFileDialogClose();
    }

    const handleChangeStatus = () => {
        setActiveLegalCase(null);
        handleChangeStatusDialogClose();
        reloadFirstPage();
    }

    const handleSaveNotice = () => {
        setActiveLegalCase(null);
        setAddNoticeDialogOpen(false);
    };

    const handleClearStatus = () => setStatusFilter(null);

    const getCaseDetailUrl = (legalCase: LegalCaseDTO) => {
        switch (legalCase.type) {
            case CaseType.Law:
                return `/hukuk-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.Investigation:
                return `/sorusturmalar/${legalCase.id}/ayrintilar`;
            case CaseType.Criminal :
                return `/ceza-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.Executive:
                return `/icra-takibi/${legalCase.id}/ayrintilar`;
            default:
                return `/hukuk-davalari/${legalCase.id}/ayrintilar`;
        }
    }

    const exportToExcel = () => {
        const headers = [[
            "KONU", prosecutorsLabel, defendantsLabel, fileNumberLabel, courtHouseLabel, courtLabel, caseDateLabel, "DURUM"
        ]];
        const worksheet = XLSX.utils.json_to_sheet(
            (allPaginatedLegalCases?.entity || []).map((legalCase) => ({
                "KONU": legalCase.name || "-",
                [prosecutorsLabel]: legalCase.prosecutors?.map((prosecutor) => {
                    switch (prosecutor.type as LitigantType) {
                        case LitigantType.Company:
                            return prosecutor.company?.value;
                        case LitigantType.Client:
                            return prosecutor.client?.value;
                        case LitigantType.FreeText:
                            return prosecutor.name;
                    }
                }).join(', '),
                [defendantsLabel]: legalCase.defendants?.map((defendant) => {
                    switch (defendant.type as LitigantType) {
                        case LitigantType.Company:
                            return defendant.company?.value;
                        case LitigantType.Client:
                            return defendant.client?.value;
                        case LitigantType.FreeText:
                            return defendant.name;
                    }
                }).join(', '),
                [fileNumberLabel]: legalCase.fileNumber,
                [courtHouseLabel]: courtHouseVisible ? legalCase.court.courtHouse.name : "-",
                [courtLabel]: courtVisible ? legalCase.court.name : "-",
                [caseDateLabel]: legalCase.caseDate ? moment.unix(legalCase.caseDate).format('DD MMMM YYYY') : "-",
                "DURUM": getPresentableLegalCaseStatus(legalCase.status),
            }))
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(worksheet, headers);

        const headerRow = headers[0];
        const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
        worksheet['!cols'] = columnWidths;
        headerRow.forEach((header, index) => {
            const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
            if (worksheet[cellRef]) {
                worksheet[cellRef].s = {
                    font: {bold: true},
                    fill: {
                        patternType: "solid",
                        fgColor: {rgb: "D3D3D3"}
                    }
                };
            }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, title);
        XLSX.writeFile(workbook, `${title.toLowerCase().replace(/\s/g, '_')}_listesi.xlsx`);
    };

    const exportToPDF = () => {
        const doc = new jsPDF();

        doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularBase64);
        doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
        doc.setFont('Roboto');

        doc.setFontSize(18);
        doc.text(title, 14, 20);

        const tableData = (allPaginatedLegalCases?.entity || []).map((legalCase) => [
            legalCase.name || "-",
            legalCase.prosecutors?.map((prosecutor) => {
                switch (prosecutor.type as LitigantType) {
                    case LitigantType.Company:
                        return prosecutor.company?.value;
                    case LitigantType.Client:
                        return prosecutor.client?.value;
                    case LitigantType.FreeText:
                        return prosecutor.name;
                }
            }).join(', '),
            legalCase.defendants?.map((defendant) => {
                switch (defendant.type as LitigantType) {
                    case LitigantType.Company:
                        return defendant.company?.value;
                    case LitigantType.Client:
                        return defendant.client?.value;
                    case LitigantType.FreeText:
                        return defendant.name;
                }
            }).join(', '),
            legalCase.fileNumber,
            courtHouseVisible ? legalCase.court.courtHouse.name : "-",
            courtVisible ? legalCase.court.name : "-",
            legalCase.caseDate ? moment.unix(legalCase.caseDate).format('DD MMMM YYYY') : "-",
            getPresentableLegalCaseStatus(legalCase.status),
        ]);

        const tableColumns = [
            "KONU",
            prosecutorsLabel,
            defendantsLabel,
            fileNumberLabel,
            courtHouseVisible ? courtHouseLabel : "",
            courtVisible ? courtLabel : "",
            caseDateLabel,
            "DURUM"
        ].filter(Boolean);

        autoTable(doc, {
            head: [tableColumns],
            body: tableData,
            startY: 30,
            margin: {horizontal: 10},
            styles: {
                font: 'Roboto',
                cellPadding: 3,
                fontSize: 8,
                valign: 'middle',
                halign: 'left'
            },
            columnStyles: {
                0: {cellWidth: 30},
                1: {cellWidth: 25},
                2: {cellWidth: 25},
                3: {cellWidth: 20},
                4: {cellWidth: 25},
                5: {cellWidth: 25},
                6: {cellWidth: 20},
                7: {cellWidth: 20},
                8: {cellWidth: 20}
            }
        });

        doc.save(`${title.toLowerCase().replace(/\s/g, '_')}_listesi.pdf`);
    };

    const colSpan = 7 + [
        reportedEntitiesVisible,
        followTypeVisible,
        courtHouseVisible,
        courtVisible,
        investigationDateVisible,
        statementDateVisible
    ].filter(Boolean).length;

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>{title.toLocaleUpperCase("tr")}</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            Excel'e Dışa Aktar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportToPDF}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            PDF'e Dışa Aktar
                        </Button>
                    </Box>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleCreateLegalCaseDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>

            <Table sx={{minWidth: 800}} aria-label='criminal case table'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: "11%"}}>
                            <TextField
                                name="name"
                                margin="dense"
                                style={{minWidth: "210px"}}
                                label="Konu"
                                variant="outlined"
                                size="small"
                                value={nameFilter}
                                onChange={applyNameFilter}/>
                        </TableCell>
                        <TableCell style={{width: "11%"}}/>
                        <TableCell style={{width: "11%"}}/>
                        {reportedEntitiesVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}
                        {followTypeVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}
                        <TableCell style={{width: "11%"}}>
                            <TextField
                                name="fileNumber"
                                margin="dense"
                                style={{minWidth: "210px"}}
                                label={fileNumberLabel}
                                variant="outlined"
                                size="small"
                                value={fileNumberFilter}
                                onChange={applyFileNumberFilter}/>
                        </TableCell>
                        {courtHouseVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}
                        {courtVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}

                        {investigationDateVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}
                        <TableCell style={{width: "11%"}}/>
                        {statementDateVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}
                        <TableCell>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="status-label">Durumu</InputLabel>
                                <Select
                                    style={{minWidth: "210px"}}
                                    labelId="status-label"
                                    label="Durumu"
                                    value={statusFilter || ""}
                                    onChange={applyStatusFilter}
                                    defaultValue=""
                                    endAdornment={
                                        statusFilter && (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClearStatus}>
                                                    <ClearIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }>
                                    {statusOptions.map((status) => (
                                        <MenuItem key={status} value={status}>
                                            {getPresentableLegalCaseStatus(status)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell style={{width: "11%"}}/>
                    </TableRow>
                    <TableRow>
                        <TableCell>KONU</TableCell>
                        <TableCell>{prosecutorsLabel}</TableCell>
                        <TableCell>{defendantsLabel}</TableCell>
                        {reportedEntitiesVisible && (
                            <TableCell>İHBAR OLUNANLAR</TableCell>
                        )}
                        {followTypeVisible && (
                            <TableCell>{followTypeLabel}</TableCell>
                        )}

                        <TableCell>{fileNumberLabel}</TableCell>

                        {courtHouseVisible && (
                            <TableCell>{courtHouseLabel}</TableCell>
                        )}

                        {courtVisible && (
                            <TableCell>{courtLabel}</TableCell>
                        )}

                        {investigationDateVisible && (
                            <TableCell>{investigationDateLabel}</TableCell>
                        )}

                        <TableCell>{caseDateLabel}</TableCell>
                        {statementDateVisible && (
                            <TableCell>{statementDateLabel}</TableCell>
                        )}
                        <TableCell>DURUMU</TableCell>
                        <TableCell>İŞLEMLER</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allPaginatedLegalCases?.entity.map((legalCase) => (
                        <TableRow key={legalCase.id}>
                            <TableCell>
                                <Link to={getCaseDetailUrl(legalCase)} className="custom-link">
                                    {legalCase.name || "-"}
                                </Link>
                            </TableCell>
                            <TableCell>
                                {legalCase.prosecutors?.map((prosecutor) => {
                                    switch (prosecutor.type as LitigantType) {
                                        case LitigantType.Company:
                                            return prosecutor.company?.value;
                                        case LitigantType.Client:
                                            return prosecutor.client?.value;
                                        case LitigantType.FreeText:
                                            return prosecutor.name;
                                    }
                                }).join(', ')}
                            </TableCell>
                            <TableCell>
                                {legalCase.defendants?.map((defendant) => {
                                    switch (defendant.type as LitigantType) {
                                        case LitigantType.Company:
                                            return defendant.company?.value;
                                        case LitigantType.Client:
                                            return defendant.client?.value;
                                        case LitigantType.FreeText:
                                            return defendant.name;
                                    }
                                }).join(', ')}
                            </TableCell>
                            {reportedEntitiesVisible && (
                                <TableCell>{legalCase.reportedEntities?.map((reportedEntity) => {
                                    switch (reportedEntity.type as LitigantType) {
                                        case LitigantType.Company:
                                            return reportedEntity.company?.value;
                                        case LitigantType.Client:
                                            return reportedEntity.client?.value;
                                        case LitigantType.FreeText:
                                            return reportedEntity.name;
                                    }
                                }).join(', ')}</TableCell>
                            )}
                            {followTypeVisible && (
                                <TableCell>-</TableCell>
                            )}
                            <TableCell>{legalCase.fileNumber}</TableCell>
                            {courtHouseVisible && (
                                <TableCell>{legalCase.court.courtHouse.name}</TableCell>
                            )}

                            {courtVisible && (
                                <TableCell>{legalCase.court.name}</TableCell>
                            )}

                            {investigationDateVisible && (
                                <TableCell>
                                    {legalCase.investigationDate
                                        ? moment.unix(legalCase.investigationDate).format('DD MMMM YYYY')
                                        : "-"
                                    }
                                </TableCell>
                            )}

                            <TableCell>
                                {legalCase.caseDate
                                    ? moment.unix(legalCase.caseDate).format('DD MMMM YYYY')
                                    : "-"
                                }
                            </TableCell>
                            {statementDateVisible && (
                                <TableCell>
                                    -
                                </TableCell>
                            )}
                            <TableCell>{getPresentableLegalCaseStatus(legalCase.status)}</TableCell>
                            <TableCell>
                                <LegalCaseAction
                                    legalCase={legalCase}
                                    onEdit={openEdit}
                                    onAddFile={openAddFile}
                                    onAddNotice={openAddNotice}
                                    onChangeStatus={openChangeStatus}
                                    onAddTask={openAddTask}/>
                            </TableCell>
                        </TableRow>
                    ))}
                    {loadLegalCasesTaskStatus?.type !== AsyncTaskStatusType.Loading && allPaginatedLegalCases?.numberOfElements === 0 && (
                        <TableRow>
                            <TableCell colSpan={colSpan} style={{textAlign: "center"}}>
                                {title} Bulunamadı
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {createLegalCaseDialogOpen && (
                <CreateLegalCaseDialog
                    page={page}
                    caseType={caseType}
                    handleClose={handleCreateLegalCaseDialogClose}
                    onAddLegalCase={handleAdd}/>
            )}
            {editDialogOpen && activeLegalCase && (
                <EditLegalCasePopup
                    legalCase={activeLegalCase}
                    onClose={handleEditLegalCaseDialogClose}
                    onEditLegalCase={handleEdit}/>
            )}
            {addNoteDialogOpen && activeLegalCase && (
                <AddNoteLegalCasePopup
                    legalCase={activeLegalCase}
                    onClose={handleAddNoteDialogClose}
                    onAddNoteLegalCase={handleAddNote}/>
            )}
            {addFileDialogOpen && activeLegalCase && (
                <AddFileToLegalCaseDialog
                    legalCase={activeLegalCase}
                    onClose={handleAddFileDialogClose}
                    onFileAdded={handleFileAdded}/>
            )}
            {addNoticeDialogOpen && activeLegalCase && (
                <AddNoticeLegalCasePopup
                    legalCase={activeLegalCase}
                    onClose={handleAddNoticeDialogClose}
                    onNoticeAdded={handleSaveNotice}/>
            )}

            {deleteDialogOpen && activeLegalCase && (
                <DeleteLegalCasePopup
                    legalCase={activeLegalCase}
                    onClose={handleDeleteLegalCaseDialogClose}
                    onDeleteLegalCase={handleDelete}
                    page={page}/>
            )}

            {changeStatusDialogOpen && activeLegalCase && (
                <ChangeStatusLegalCasePopup
                    legalCase={activeLegalCase}
                    onClose={handleChangeStatusDialogClose}
                    onChangeStatus={handleChangeStatus}/>
            )}

            {allPaginatedLegalCases?.totalNumberPages != null && allPaginatedLegalCases.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedLegalCases.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
}
