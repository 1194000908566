import {refreshTokenAction} from "../api/auth";
import {AppState} from "../store";
import {unwrapResult} from "@reduxjs/toolkit";
import {useLocation, useParams} from "react-router-dom";
import {useMemo} from "react";

//use correct expiry date like backend side
export const expiresIn = 3600;

// TODO remove this function after change normal action functions to redux actions
export const getAppTokenOld = async (dispatch: any) => {
    const token = localStorage.getItem("hukuk-app-token");
    const expiry = localStorage.getItem("hukuk-app-token-expiry");

    if (!token || !expiry) {
        return null;
    }
    if (Date.now() > Number(expiry)) {
        return unwrapResult(await dispatch(refreshTokenAction()));
    }

    return token;
}

export const getAppToken = async (dispatch: any, getState: () => AppState) => {
    const state = getState();
    const token = state.auth.userWithToken?.token;
    const expiry = state.auth.userWithToken?.tokenExpiryIn;

    if (!token || !expiry) {
        return null;
    }
    if (Date.now() > Number(expiry)) {
        return unwrapResult(await dispatch(refreshTokenAction()));
    }
    return state.auth.userWithToken?.token;
}

export const getAppUserId = () => localStorage.getItem("hukuk-app-userId") || sessionStorage.getItem("hukuk-app-userId");

export const useHandleURLQueries = (path: string | undefined) => {
    const location = useLocation();
    const params = useParams();

    return useMemo(() => {
        if (Object.keys(params).length && path) {
            const arr = Object.keys(params);

            // Check if the current path and query parameter are included in the URL
            return (location.pathname.startsWith(path) || location.pathname.startsWith(params[arr[0]] as any)) && path !== '/';
        }

        return false;
    }, [location.pathname, params, path]);
};

export interface Option {
    label: string;
    value: number;
}
