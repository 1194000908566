import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {getPresentableLitigantType, LitigantType} from "../../api/litigantType";
import {AddLitigantToCaseDTO} from "../../api/dtos";
import ErrorText from "../../components/ErrorText";
import CustomDialog from "../../components/CustomDialog";
import {getAllCompaniesAction} from "../../api/settings";
import {AddLitigantType} from "./createLegalCaseDialog";

interface AddReportedEntitiesDialogProps {
    handleClose: () => void;
    addReportedEntities: (result: AddLitigantType) => void;
    excludeItems: AddLitigantToCaseDTO[];
}

interface FormData {
    litigantType: LitigantType;
    selection: number | null;
    name: string;
}

export const AddReportedEntitiesDialog: React.FC<AddReportedEntitiesDialogProps> = ({
                                                                                        handleClose,
                                                                                        addReportedEntities,
                                                                                        excludeItems
                                                                                    }) => {
    const allClients = useSelector((state: AppState) => state.settings.allClientsLookup);
    const allCompanies = useSelector((state: AppState) => state.settings.allCompanies);
    const dispatch: any = useDispatch();

    const excludedClientIds = excludeItems
        .filter((excludeItem) => excludeItem.type === LitigantType.Client)
        .map((excludeItem) => excludeItem.clientId);

    const filteredAllClients = allClients?.filter(
        (client) => !excludedClientIds.includes(client.id)
    );
    const excludedCompanyIds = excludeItems
        .filter((excludeItem) => excludeItem.type === LitigantType.Company)
        .map((excludeItem) => excludeItem.companyId);

    const filteredAllCompanies = allCompanies?.filter(
        (company) => !excludedCompanyIds.includes(company.id)
    );

    const initialFormData: FormData = {
        litigantType: LitigantType.Company,
        selection: null,
        name: ""
    };

    const litigantTypeRef = useRef(initialFormData.litigantType);
    const [formData, setFormData] = useState<FormData>(initialFormData);

    useEffect(() => {
        if (formData.litigantType !== litigantTypeRef.current) {
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                selection: null,
                name: "",
            }));
        }
        litigantTypeRef.current = formData.litigantType;
    }, [formData.litigantType]);

    useEffect(() => {
        if (!allCompanies || allCompanies.length === 0) {
            dispatch(getAllCompaniesAction());
        }
    }, [dispatch, allCompanies]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let selection = undefined;
        let name = undefined;
        switch (formData.litigantType) {
            case LitigantType.Company:
                selection = allCompanies?.find((it) => it.id === formData.selection);
                break;
            case LitigantType.Client:
                selection = allClients?.find((it) => it.id === formData.selection);
                break;
            case LitigantType.FreeText:
                name = formData.name;
        }

        addReportedEntities({
            litigantType: formData.litigantType,
            selection,
            name
        });
        handleClose();
    };

    const handleChangeFormData = (event: any) => {
        const {name, value, type, checked} = event.target;

        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked
            });
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                // @ts-ignore
                [name]: Array.from(event.target.selectedOptions, (option) => option.value)
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };
    const excludedFreeTexts = excludeItems
        .filter((excludeItem) => excludeItem.type === LitigantType.FreeText)
        .map((excludeItem) => excludeItem.name);

    const freeTextNotSuitable = formData.litigantType === LitigantType.FreeText && excludedFreeTexts.includes(formData.name);
    const submitDisabled = freeTextNotSuitable || ([LitigantType.Company, LitigantType.Client].includes(formData.litigantType)
        ? formData.selection == null
        : formData.name === "");

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}>
            <DialogTitle>İhbar Olunan Seç</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="litigantType-label">
                        İhbar Olunan Tipi
                    </InputLabel>
                    <Select
                        autoFocus={true}
                        labelId="status-label"
                        id="litigantType"
                        name="litigantType"
                        value={formData.litigantType}
                        label="İhbar Olunan Tipi"
                        onChange={handleChangeFormData}>
                        {Object.values(LitigantType).map((litigantType) => (
                            <MenuItem key={litigantType} value={litigantType}>
                                {getPresentableLitigantType(litigantType)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {formData.litigantType === LitigantType.Client && (
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <InputLabel id="selection-label">İhbar Olunan</InputLabel>
                        <Select
                            labelId="status-label"
                            id="selection"
                            name="selection"
                            value={formData.selection}
                            label="İhbar Olunan"
                            onChange={handleChangeFormData}>
                            {filteredAllClients?.map((client) => (
                                <MenuItem key={client.id} value={client.id}>{client.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {formData.litigantType === LitigantType.Company && (
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <InputLabel id="selection-label">İhbar Olunan</InputLabel>
                        <Select
                            labelId="status-label"
                            id="selection"
                            name="selection"
                            value={formData.selection}
                            label="İhbar Olunan"
                            onChange={handleChangeFormData}>
                            {filteredAllCompanies?.map((company) => (
                                <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {formData.litigantType === LitigantType.FreeText && (
                    <FormControl fullWidth={true} sx={{mb: 1}}>
                        <TextField
                            margin="dense"
                            id="name"
                            name="name"
                            label="İhbar Olunan"
                            type="text"
                            fullWidth
                            variant="outlined"
                            onChange={handleChangeFormData}/>
                    </FormControl>
                )}
                {freeTextNotSuitable && (
                    <ErrorText message="Daha önceden kullanılmış ihbar olunan"/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit" disabled={submitDisabled}>Ekle</Button>
            </DialogActions>
        </CustomDialog>
    );
}
