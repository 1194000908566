import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllClientsAction} from "../api/settings";
import UserLayout from "../components/UserLayout";
import Box from "@mui/material/Box";
import {CreateClientDialog} from "./create-client/createClientDialog";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import * as XLSX from "xlsx-js-style";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {robotoRegularBase64} from '../shared/pdf-fonts';
import {AppState} from "../store";

export default function Clients() {
    const dispatch: any = useDispatch();
    const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(getAllClientsAction());
    }, [dispatch]);

    const handleCreateClientDialogOpen = () => setCreateClientDialogOpen(true);
    const handleCreateClientDialogClose = () => setCreateClientDialogOpen(false);

    const clients = useSelector((state: AppState) => state.settings.allClientsLookup);

    const exportToExcel = () => {
        if (clients) {
            const headers = [[
                "Şirket Adı"
            ]];
            const worksheet = XLSX.utils.json_to_sheet(
                clients.map((client: any) => ({
                    "Şirket Adı": client.value,
                }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(worksheet, headers);

            const headerRow = headers[0];
            const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
            worksheet['!cols'] = columnWidths;
            headerRow.forEach((_, index) => {
                const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
                if (worksheet[cellRef]) {
                    worksheet[cellRef].s = {
                        font: {bold: true},
                        fill: {
                            patternType: "solid",
                            fgColor: {rgb: "D3D3D3"}
                        }
                    };
                }
            });

            XLSX.utils.book_append_sheet(workbook, worksheet, "Şirket Listesi");
            XLSX.writeFile(workbook, "diğer_şirketler_listesi.xlsx");
        }
    };

    const exportToPDF = () => {
        if (clients) {
            const doc = new jsPDF();
            doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularBase64);
            doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
            doc.setFont('Roboto');

            doc.setFontSize(18);
            doc.text('Diğer Şirketler Listesi', 14, 20);

            const tableData = clients.map((client: any) => [
                client.value,
            ]);

            const tableColumns = ['Şirket Adı'];

            autoTable(doc, {
                head: [tableColumns],
                body: tableData,
                startY: 30,
                margin: {horizontal: 10},
                styles: {
                    font: 'Roboto',
                    cellPadding: 3,
                    fontSize: 10,
                    valign: 'middle',
                    halign: 'left'
                }
            });

            doc.save('diger_sirketler_listesi.pdf');
        }
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>DİĞER ŞİRKETLER</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            Excel'e Dışa Aktar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportToPDF}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            PDF'e Dışa Aktar
                        </Button>
                    </Box>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleCreateClientDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>

            {createClientDialogOpen && (
                <CreateClientDialog handleClose={handleCreateClientDialogClose}/>
            )}

            <Table sx={{minWidth: 800}} aria-label='clients table'>
                <TableHead>
                    <TableRow>
                        <TableCell>ADI</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clients?.map((client: any) => (
                        <TableRow key={client.id}>
                            <TableCell>{client.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </UserLayout>
    );
}
