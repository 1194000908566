import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Button, Grid, Paper, Typography} from "@mui/material";
import UserLayout from "../components/UserLayout";
import {ResetOwnPasswordDialog} from "./reset-own-password/resetOwnPasswordDialog";
import {AppState} from "../store";
import {getPresentableUserRole} from "../api/dtos";

const ProfileDetailsRow: React.FC<{ label: string; value: string | number }> = ({label, value}) => (
    <Grid item xs={6} container gap={1}>
        <Typography variant="body1" fontWeight="bold" display="inline">
            {label}:
        </Typography>
        <Typography variant="body1" display="inline">
            {value || "Veri yok"}
        </Typography>
    </Grid>
);

export default function Profile() {
    const user = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const [resetOwnPasswordDialogOpen, setResetOwnPasswordDialogOpen] = useState(false);

    const handleResetOwnPasswordDialogOpen = () => setResetOwnPasswordDialogOpen(true);
    const handleResetOwnPasswordDialogClose = () => setResetOwnPasswordDialogOpen(false);

    if (!user) {
        return null;
    }
    return (
        <UserLayout>
            <Paper elevation={3} sx={{p: 3}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                            {user.firstName + " " + user.lastName} Profil Sayfası
                        </Typography>
                    </Grid>
                    <ProfileDetailsRow label="E-MAİL" value={user.email}/>
                    <ProfileDetailsRow label="ADI" value={user.firstName}/>
                    <ProfileDetailsRow label="SOYADI" value={user.lastName}/>
                    <ProfileDetailsRow label="ROLÜ" value={getPresentableUserRole(user.role)}/>
                </Grid>
                <Button
                    variant="outlined"
                    component="span"
                    onClick={handleResetOwnPasswordDialogOpen}
                    sx={{
                        mt: 2,
                        borderRadius: 2,
                        padding: '8px 16px',
                        boxShadow: 2,
                        '&:hover': {
                            backgroundColor: 'grey.100',
                        }
                    }}>
                    Şifre Değiştir
                </Button>
            </Paper>
            {resetOwnPasswordDialogOpen && (
                <ResetOwnPasswordDialog handleClose={handleResetOwnPasswordDialogClose}/>
            )}
        </UserLayout>
    );
}
