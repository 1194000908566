import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate, useSearchParams} from "react-router-dom";
import {createCourtHouseAction, getCourtHousesAction, getLoadCitiesAction} from "../../api/settings";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {TextFieldVariants} from "@mui/material/TextField/TextField";
import ErrorText from "../../components/ErrorText";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import {setLoadCitiesTaskStatus} from "../../features/settings/settingsSlice";
import TextField from "@mui/material/TextField";
import CustomDialog from "../../components/CustomDialog";
import {AppState} from "../../store";

interface CreateCourtHouseProps {
    handleClose: () => void;
}

interface AddCreateLegalCaseFormData {
    cityId: number | null;
    courtHouseId: number | null;
}

export const CreateCourtHouse: React.FC<CreateCourtHouseProps> = ({handleClose}) => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const allCitiesLookup = useSelector((state: AppState) => state.settings.allCitiesLookup);
    const createCourtHouseTaskStatus = useSelector((state: AppState) => state.settings.createCourtHouseTaskStatus);
    const [errors, setErrors] = useState<FieldErrors>({});
    const defaultFormData: AddCreateLegalCaseFormData = {
        cityId: null,
        courtHouseId: null,
    };
    const [formData, setFormData] = useState<AddCreateLegalCaseFormData>(defaultFormData);

    useEffect(() => {
        dispatch(getLoadCitiesAction());
        return () => {
            dispatch(setLoadCitiesTaskStatus(null));
        }
    }, [dispatch]);
    const handleChangeFormData = (event: any) => {
        const {name, value, type, checked} = event.target;

        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked
            });
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                // @ts-ignore
                [name]: Array.from(event.target.selectedOptions, (option) => option.value)
            });
        } else {
            let sideEffects = {};
            if (name === "cityId") {
                sideEffects = {
                    courtId: null,
                    courtHouseId: null
                };
            }
            if (name === "courtHouseId") {
                sideEffects = {
                    courtId: null
                };
            }
            setFormData({
                ...formData,
                [name]: value,
                ...sideEffects
            });
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson: any = {};
        formData.forEach((value, key) => {
            formJson[key] = value;
        });

        const result = await dispatch(createCourtHouseAction(formJson));

        if (result) {
            handleClose();

            if (page === 1) {
                dispatch(getCourtHousesAction({
                    filterByName: "",
                    page
                }));
            } else {
                navigate("/adliyeler");
            }
        }
    };

    const fieldVariant = "outlined"

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Adliye Oluştur</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Adı"
                        type="text"
                        fullWidth
                        variant="outlined"/>
                </FormControl>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="city-id-label">Şehirler</InputLabel>
                    <Select
                        required
                        labelId="city-id-label"
                        id="cityId"
                        name="cityId"
                        label="Şehirler"
                        fullWidth
                        variant={fieldVariant as TextFieldVariants}
                        value={formData.cityId || ''}
                        onChange={handleChangeFormData}>
                        {allCitiesLookup?.map((cityLookup: any) => (
                            <MenuItem key={cityLookup.id} value={cityLookup.id}>
                                {cityLookup.value}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.cities && (
                        <ErrorText message={errors.cities}/>
                    )}
                    {createCourtHouseTaskStatus?.type === AsyncTaskStatusType.Error && createCourtHouseTaskStatus.errorMessage && (
                        <ErrorText type="form" message={createCourtHouseTaskStatus.errorMessage}/>
                    )}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Oluştur</Button>
            </DialogActions>
        </CustomDialog>
    );
};
