import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {CompanyDTO, CourtDTO, CourtHouseDTO, PaginatedEntityList, SettingsLookupDTO} from "../../api/dtos";

export interface SettingsState {
    allCompanies: CompanyDTO[] | null;
    allClientsLookup: SettingsLookupDTO[] | null;
    allCitiesLookup: SettingsLookupDTO[] | null;
    allCourts: CourtDTO[] | null;
    allCourtHouses: CourtHouseDTO[] | null;
    loadAllCompaniesTaskStatus: AsyncTaskStatus | null;
    loadAllPaginatedCompaniesTaskStatus: AsyncTaskStatus | null;
    loadAllClientsTaskStatus: AsyncTaskStatus | null;
    createCompanyTaskStatus: AsyncTaskStatus | null;
    editCompanyTaskStatus: AsyncTaskStatus | null;
    deleteCompanyTaskStatus: AsyncTaskStatus | null;
    createClientTaskStatus: AsyncTaskStatus | null;
    editClientTaskStatus: AsyncTaskStatus | null;
    deleteClientTaskStatus: AsyncTaskStatus | null;
    createLegalNoticeTaskStatus: AsyncTaskStatus | null;
    loadUserDataTaskStatus: AsyncTaskStatus | null;
    createCourtTaskStatus: AsyncTaskStatus | null;
    createCourtHouseTaskStatus: AsyncTaskStatus | null;
    loadUsersTaskStatus: AsyncTaskStatus | null;
    loadCourtHousesTaskStatus: AsyncTaskStatus | null;
    loadCitiesTaskStatus: AsyncTaskStatus | null;
    allPaginatedCourtHouses: PaginatedEntityList<CourtHouseDTO> | null;
    allPaginatedCourts: PaginatedEntityList<CourtDTO> | null;
    loadAllCourtsTaskStatus: AsyncTaskStatus | null;
    loadAllCourtHousesTaskStatus: AsyncTaskStatus | null;
    deleteLegalCaseTaskStatus: AsyncTaskStatus | null;
    loadCourtsTaskStatus: AsyncTaskStatus | null;
    loadEmailSettingsTaskStatus: AsyncTaskStatus | null;
    updateEmailSettingsTaskStatus: AsyncTaskStatus | null;
    emailSettings: { [index: string]: string; } | null;
    editCourtHouseTaskStatus: AsyncTaskStatus | null;
    deleteCourtHouseTaskStatus: AsyncTaskStatus | null;
    editCourtTaskStatus: AsyncTaskStatus | null;
    deleteCourtTaskStatus: AsyncTaskStatus | null;
    allPaginatedCompanies: PaginatedEntityList<CompanyDTO> | null;
    allPaginatedCities: PaginatedEntityList<SettingsLookupDTO> | null;
    getAllPaginatedCitiesStatus: AsyncTaskStatus | null;
}

const initialState: SettingsState = {
    loadUserDataTaskStatus: null,
    loadAllCourtsTaskStatus: null,
    allCitiesLookup: null,
    deleteCourtTaskStatus: null,
    allCompanies: null,
    allPaginatedCities: null,
    getAllPaginatedCitiesStatus: null,
    editCourtTaskStatus: null,
    allCourtHouses: null,
    allClientsLookup: null,
    loadAllCompaniesTaskStatus: null,
    loadAllPaginatedCompaniesTaskStatus: null,
    loadAllClientsTaskStatus: null,
    loadCourtHousesTaskStatus: null,
    createCompanyTaskStatus: null,
    editCompanyTaskStatus: null,
    deleteCompanyTaskStatus: null,
    createClientTaskStatus: null,
    createLegalNoticeTaskStatus: null,
    editClientTaskStatus: null,
    deleteClientTaskStatus: null,
    createCourtTaskStatus: null,
    createCourtHouseTaskStatus: null,
    loadUsersTaskStatus: null,
    loadCitiesTaskStatus: null,
    allPaginatedCourtHouses: null,
    allCourts: null,
    loadAllCourtHousesTaskStatus: null,
    deleteLegalCaseTaskStatus: null,
    allPaginatedCourts: null,
    loadCourtsTaskStatus: null,
    loadEmailSettingsTaskStatus: null,
    updateEmailSettingsTaskStatus: null,
    emailSettings: null,
    editCourtHouseTaskStatus: null,
    deleteCourtHouseTaskStatus: null,
    allPaginatedCompanies: null
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setLoadUserDataTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadUserDataTaskStatus = action.payload;
        },
        setDeleteLegalCaseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteLegalCaseTaskStatus = action.payload;
        },
        setLoadAllCourtsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllCourtsTaskStatus = action.payload;
        },
        setAllCompanies: (state, action: PayloadAction<CompanyDTO[] | null>) => {
            state.allCompanies = action.payload;
        },
        setAllClientsLookup: (state, action: PayloadAction<SettingsLookupDTO[] | null>) => {
            state.allClientsLookup = action.payload;
        },
        setGetAllCompaniesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllCompaniesTaskStatus = action.payload;
        },
        setGetAllPaginatedCompaniesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllPaginatedCompaniesTaskStatus = action.payload;
        },
        setGetAllClientsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllClientsTaskStatus = action.payload;
        },
        setCreateLegalNoticeTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createLegalNoticeTaskStatus = action.payload;
        },
        setCreateCompanyTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createCompanyTaskStatus = action.payload;
        },
        setEditCompanyTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editCompanyTaskStatus = action.payload;
        },
        setDeleteCompanyTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteCompanyTaskStatus = action.payload;
        },
        setCreateClientTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createClientTaskStatus = action.payload;
        },
        setEditClientTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editClientTaskStatus = action.payload;
        },
        setDeleteClientTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteClientTaskStatus = action.payload;
        },
        setCreateCourtTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createCourtTaskStatus = action.payload;
        },
        setCreateHouseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createCourtHouseTaskStatus = action.payload;
        },
        setLoadUsersTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadUsersTaskStatus = action.payload;
        },
        setLoadCitiesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadCitiesTaskStatus = action.payload;
        },
        setAllCitiesLookup: (state, action: PayloadAction<SettingsLookupDTO[] | null>) => {
            state.allCitiesLookup = action.payload;
        },
        setLoadCourtHousesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadCourtHousesTaskStatus = action.payload;
        },
        setAllPaginatedCourtHouses: (state, action: PayloadAction<PaginatedEntityList<CourtHouseDTO> | null>) => {
            state.allPaginatedCourtHouses = action.payload;
        },
        setAllPaginatedCourts: (state, action: PayloadAction<PaginatedEntityList<CourtDTO> | null>) => {
            state.allPaginatedCourts = action.payload;
        },
        setAllCourts: (state, action: PayloadAction<CourtDTO[] | null>) => {
            state.allCourts = action.payload;
        },
        setAllCourtHouses: (state, action: PayloadAction<CourtHouseDTO[] | null>) => {
            state.allCourtHouses = action.payload;
        },
        setLoadCourtsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadCourtsTaskStatus = action.payload;
        },
        setLoadEmailSettingsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadEmailSettingsTaskStatus = action.payload;
        },
        setUpdateEmailSettingsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.updateEmailSettingsTaskStatus = action.payload;
        },
        setEmailSettings: (state, action: PayloadAction<{ [index: string]: string; } | null>) => {
            state.emailSettings = action.payload;
        },
        setEditCourtHouseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editCourtHouseTaskStatus = action.payload;
        },
        setEditCourtTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editCourtTaskStatus = action.payload;
        },
        setDeleteCourtTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteCourtTaskStatus = action.payload;
        },
        setDeleteCourtHouseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteCourtHouseTaskStatus = action.payload;
        },
        setAllPaginatedCompanies: (state, action: PayloadAction<PaginatedEntityList<CompanyDTO> | null>) => {
            state.allPaginatedCompanies = action.payload;
        },
        setGetAllPaginatedCitiesStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.getAllPaginatedCitiesStatus = action.payload;
        },
        setAllPaginatedCities: (state, action: PayloadAction<PaginatedEntityList<SettingsLookupDTO> | null>) => {
            state.allPaginatedCities = action.payload;
        }
    }
});

export const {
    setLoadCourtsTaskStatus,
    setAllPaginatedCourts,
    setAllPaginatedCourtHouses,
    setLoadCourtHousesTaskStatus,
    setGetAllCompaniesTaskStatus,
    setGetAllPaginatedCompaniesTaskStatus,
    setGetAllClientsTaskStatus,
    setDeleteCompanyTaskStatus,
    setEditCompanyTaskStatus,
    setCreateCompanyTaskStatus,
    setAllCompanies,
    setAllClientsLookup,
    setCreateClientTaskStatus,
    setEditClientTaskStatus,
    setDeleteClientTaskStatus,
    setLoadUserDataTaskStatus,
    setCreateCourtTaskStatus,
    setCreateHouseTaskStatus,
    setCreateLegalNoticeTaskStatus,
    setLoadUsersTaskStatus,
    setLoadCitiesTaskStatus,
    setAllCitiesLookup,
    setLoadAllCourtsTaskStatus,
    setAllCourts,
    setAllCourtHouses,
    setDeleteLegalCaseTaskStatus,
    setLoadEmailSettingsTaskStatus,
    setUpdateEmailSettingsTaskStatus,
    setEmailSettings,
    setEditCourtHouseTaskStatus,
    setEditCourtTaskStatus,
    setDeleteCourtTaskStatus,
    setDeleteCourtHouseTaskStatus,
    setAllPaginatedCompanies,
    setGetAllPaginatedCitiesStatus,
    setAllPaginatedCities
} = settingsSlice.actions;

export default settingsSlice.reducer;
