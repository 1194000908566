import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {PaginatedEntityList, TaskDTO} from "../../api/dtos";

export interface TaskState {
    allPaginatedTasks: PaginatedEntityList<TaskDTO> | null;
    getAllPaginatedTasksStatus: AsyncTaskStatus | null;
    createTaskTaskStatus: AsyncTaskStatus | null;
    updateTaskStatusTaskStatus: AsyncTaskStatus | null;
    deleteTaskTaskStatus: AsyncTaskStatus | null;
    editTaskTaskStatus: AsyncTaskStatus | null;
}

const initialState: TaskState = {
    allPaginatedTasks: null,
    getAllPaginatedTasksStatus: null,
    createTaskTaskStatus: null,
    updateTaskStatusTaskStatus: null,
    deleteTaskTaskStatus: null,
    editTaskTaskStatus: null
};

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setUpdateTaskStatusTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.updateTaskStatusTaskStatus = action.payload;
        },
        setCreateTaskTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createTaskTaskStatus = action.payload;
        },
        setGetAllPaginatedTasksTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.getAllPaginatedTasksStatus = action.payload;
        },
        setAllPaginatedTasks: (state, action: PayloadAction<PaginatedEntityList<TaskDTO> | null>) => {
            state.allPaginatedTasks = action.payload;
        },
        setDeleteTaskTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteTaskTaskStatus = action.payload;
        },
        setEditTaskTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editTaskTaskStatus = action.payload;
        }
    }
});

export const {
    setCreateTaskTaskStatus,
    setUpdateTaskStatusTaskStatus,
    setGetAllPaginatedTasksTaskStatus,
    setAllPaginatedTasks,
    setDeleteTaskTaskStatus,
    setEditTaskTaskStatus
} = taskSlice.actions;

export default taskSlice.reducer;
