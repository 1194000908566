import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {Delete, Edit, SwapHoriz} from "@mui/icons-material";
import {TaskDTO} from "../api/dtos";
import {useSelector} from "react-redux";
import {AppState} from "../store";

interface TaskActionProps {
    task: TaskDTO;
    onChangeStatus: (task: TaskDTO) => void;
    onDelete: (task: TaskDTO) => void;
    onEditTask: (task: TaskDTO) => void;
}

const TaskAction: React.FC<TaskActionProps> = ({
                                                   task,
                                                   onChangeStatus,
                                                   onDelete,
                                                   onEditTask
                                               }) => {
    const user = useSelector((state: AppState) => state.auth.userWithToken);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    const handleChangeStatus = () => handleMenuItemClick(() => onChangeStatus(task));

    const handleDeleteTask = () => handleMenuItemClick(() => onDelete(task));

    const handleEditTask = () => handleMenuItemClick(() => onEditTask(task));
    const myTask = user?.user?.id && task.assignees.map((it) => it.id).includes(user.user?.id);

    return (
        <>
            <IconButton
                aria-controls={open ? 'task-action-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                size='small' aria-label='settings' className='card-more-options'
                sx={{color: 'text.secondary'}}>
                <DotsVertical/>
            </IconButton>
            <Menu
                id="task-action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {myTask && (
                    <>
                        <MenuItem onClick={handleEditTask}>
                            <IconButton size='small' aria-label='edit' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <Edit/>
                            </IconButton>
                            Düzenle
                        </MenuItem>
                        <MenuItem onClick={handleChangeStatus}>
                            <IconButton size='small' aria-label='change status' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <SwapHoriz/>
                            </IconButton>
                            Durumunu Değiştir
                        </MenuItem>
                    </>
                )}
                <MenuItem onClick={handleDeleteTask}>
                    <IconButton size='small' aria-label='delete' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <Delete/>
                    </IconButton>
                    Görevi Sil
                </MenuItem>
            </Menu>
        </>
    );
}

export default TaskAction;
