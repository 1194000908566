import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import UserLayout from "../components/UserLayout";
import {getLegalNoticesAction} from "../api/settings";
import {AppState} from "../store";
import {setAllPaginatedLegalNotices} from "../features/notices/legalNotice";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Pagination} from "@mui/lab";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";
import moment from "moment";
import {getPresentableLegalNoticeStatus, LegalNoticeDTO, LegalNoticeStatus} from "../api/dtos";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import * as XLSX from "xlsx-js-style";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {robotoRegularBase64} from '../shared/pdf-fonts';
import {DateRangePicker} from "../components/DateRangePicker";
import NoticeAction from "../actions/NoticeAction";
import {DeleteNoticeDialog} from "./delete-notice/deleteNoticeDialog";
import {AsyncTaskStatusType} from "../shared/dtos";
import {ChangeNoticeStatusDialog} from "./create-status/changeNoticeStatusDialog";

moment.locale("tr");

export default function LegalNotices() {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const location = useLocation();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get("page") || "1");
    const allPaginatedLegalNotices = useSelector((state: AppState) => state.notices.allPaginatedLegalNotices);
    const statusOptions = Object.values(LegalNoticeStatus);
    const [textFilter, setTextFilter] = useState("");
    const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
    const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);
    const [statusFilter, setStatusFilter] = useState<LegalNoticeStatus | null>(null);
    const [userIdFilter, setUserIdFilter] = useState<number | null>(null);
    const pageRef = useRef(page);
    const textFilterRef = useRef(textFilter);
    const startDateFilterRef = useRef(startDateFilter);
    const endDateFilterRef = useRef(endDateFilter);
    const statusFilterRef = useRef(statusFilter);
    const userIdFilterRef = useRef(userIdFilter);
    const loadLegalNoticesTaskStatus = useSelector((state: AppState) => state.notices.loadLegalNoticesTaskStatus)
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const [deleteNoticeDialogOpen, setDeleteNoticeDialogOpen] = useState(false);
    const [activeNotice, setActiveNotice] = useState<LegalNoticeDTO | null>(null);

    const loadLegalNotices = useCallback(() => {
        if (currentUser?.id) {
            dispatch(getLegalNoticesAction({
                userIdFilter: currentUser.id,
                statusFilter,
                textFilter,
                startDateFilter,
                endDateFilter,
                page
            }));
        }
    }, [dispatch, page, statusFilter, currentUser?.id, startDateFilter, endDateFilter, textFilter]);

    useEffect(() => {
        loadLegalNotices();
        return () => {
            dispatch(setAllPaginatedLegalNotices(null));
        };
    }, [dispatch, loadLegalNotices]);

    useEffect(() => {
        if (
            pageRef.current !== page ||
            textFilterRef.current !== textFilter ||
            startDateFilterRef.current !== startDateFilter ||
            endDateFilterRef.current !== endDateFilter ||
            statusFilterRef.current !== statusFilter ||
            userIdFilterRef.current !== userIdFilter
        ) {
            loadLegalNotices();
            pageRef.current = page;
            textFilterRef.current = textFilter;
            startDateFilterRef.current = startDateFilter;
            endDateFilterRef.current = endDateFilter;
            statusFilterRef.current = statusFilter;
            userIdFilterRef.current = userIdFilter;
        }
    }, [page, textFilter, startDateFilter, endDateFilter, statusFilter, userIdFilter]);

    const handleDeleteNoticeDialogClose = () => setDeleteNoticeDialogOpen(false);
    const handleChangeStatusDialogOpen = () => setChangeStatusDialogOpen(true);
    const handleDeleteNoticeDialogOpen = () => setDeleteNoticeDialogOpen(true);
    const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false);
    const handleChangeStatusDialogClose = () => setChangeStatusDialogOpen(false);

    const reloadFirstPage = () => {
        if (page === 1) {
            dispatch(getLegalNoticesAction({
                page,
                textFilter,
                startDateFilter,
                endDateFilter,
                statusFilter,
                userIdFilter: currentUser?.id
            }));
        } else {
            navigate("/tebligatlar");
        }
    }

    const handleDelete = () => {
        setActiveNotice(null);
        handleDeleteNoticeDialogClose();
        reloadFirstPage();
    }

    const openDelete = (notice: LegalNoticeDTO) => {
        setActiveNotice(notice);
        handleDeleteNoticeDialogOpen();
    };

    const handleNoticeAdded = () => {
        if (page === 1) {
            loadLegalNotices();
        } else {
            navigate("?page=1");
        }
    };

    const openChangeStatus = (notice: LegalNoticeDTO) => {
        setActiveNotice(notice);
        handleChangeStatusDialogOpen();
    };

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const applyTextFilter = (e: { target: { value: any; }; }) => {
        const inputTextSearch = e.target.value;
        setTextFilter(inputTextSearch);
    };

    const applyStartDateFilter = (date: Date | null) => {
        setStartDateFilter(date);
    };

    const applyEndDateFilter = (date: Date | null) => {
        setEndDateFilter(date);
    };

    const applyStatusFilter = (e: { target: { value: string; }; }) => {
        const selectedStatus = e.target.value === "" ? null : e.target.value as LegalNoticeStatus;
        setStatusFilter(selectedStatus);
    };

    const handleClearStatus = () => setStatusFilter(null);

    const handleChangeStatus = () => {
        setActiveNotice(null);
        handleChangeStatusDialogClose();
        reloadFirstPage();
    }

    const exportToExcel = () => {
        const headers = [[
            "Tebligat Başlığı", "Açıklama", "Tebliğ Tarihi", "Son Tarih", "Durum"
        ]];
        const worksheet = XLSX.utils.json_to_sheet(
            (allPaginatedLegalNotices?.entity || []).map((notice) => ({
                "Tebligat Başlığı": notice.title,
                "Açıklama": notice.description,
                "Tebliğ Tarihi": notice.arrivalDate
                    ? moment.unix(notice.arrivalDate).format("DD MMMM YYYY")
                    : "-",
                "Son Tarih": notice.dueDate
                    ? moment.unix(notice.dueDate).format("DD MMMM YYYY")
                    : "-",
                "Durum": getPresentableLegalNoticeStatus(notice.status),
            }))
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(worksheet, headers);

        const headerRow = headers[0];
        const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
        worksheet['!cols'] = columnWidths;
        headerRow.forEach((header, index) => {
            const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
            if (worksheet[cellRef]) {
                worksheet[cellRef].s = {
                    font: {bold: true},
                    fill: {
                        patternType: "solid",
                        fgColor: {rgb: "D3D3D3"}
                    }
                };
            }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "Tebligatlar");
        XLSX.writeFile(workbook, "tebligatlar.xlsx");
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularBase64);
        doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
        doc.setFont('Roboto');
        doc.setFontSize(18);
        doc.text('Tebligatlar', 14, 20);

        const tableData = (allPaginatedLegalNotices?.entity || []).map((notice) => [
            notice.title,
            notice.description,
            notice.assignees.length > 0
                ? notice.assignees.map((user) => user.firstName + " " + user.lastName).join(', ')
                : 'Atama Yok',
            notice.arrivalDate
                ? moment.unix(notice.arrivalDate).format("DD MMMM YYYY")
                : "-",
            notice.dueDate
                ? moment.unix(notice.dueDate).format("DD MMMM YYYY")
                : "-",
            getPresentableLegalNoticeStatus(notice.status),
        ]);

        const tableColumns = ['Tebligat Başlığı', 'Açıklama', 'Atanan Kullanıcılar', 'Tebliğ Tarihi', 'Son Tarih', 'Durum'];

        autoTable(doc, {
            head: [tableColumns],
            body: tableData,
            startY: 30,
            margin: {horizontal: 10},
            styles: {
                font: 'Roboto',
                cellPadding: 3,
                fontSize: 10,
                valign: 'middle',
                halign: 'left'
            }
        });

        doc.save('tebligatlar.pdf');
    };

    return (
        <UserLayout>
            <div>
                <h1>TEBLİGATLAR</h1>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            Excel'e Dışa Aktar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportToPDF}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            PDF'e Dışa Aktar
                        </Button>
                    </Box>
                </Box>
            </div>

            {deleteNoticeDialogOpen && activeNotice != null && (
                <DeleteNoticeDialog
                    notice={activeNotice}
                    handleClose={handleDeleteNoticeDialogClose}
                    handleDelete={handleDelete}/>
            )}

            {changeStatusDialogOpen && activeNotice != null && (
                <ChangeNoticeStatusDialog
                    notice={activeNotice}
                    handleChangeStatus={handleChangeStatus}
                    handleClose={handleChangeStatusDialogClose}/>
            )}

            <Table sx={{minWidth: 800}} aria-label="legal notices table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: "16%"}}>
                            <TextField
                                margin="dense"
                                style={{minWidth: "210px"}}
                                label="Tebligat Başlığı"
                                variant="outlined"
                                size="small"
                                onChange={applyTextFilter}/>
                        </TableCell>
                        <TableCell style={{width: "16%"}}/>
                        <TableCell style={{width: "16%"}}/>
                        <TableCell style={{width: "16%"}}/>
                        <TableCell style={{width: "16%"}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                                <DateRangePicker
                                    startDate={startDateFilter}
                                    endDate={endDateFilter}
                                    applyStartDateFilter={applyStartDateFilter}
                                    applyEndDateFilter={applyEndDateFilter}/>
                            </LocalizationProvider>
                        </TableCell>
                        <TableCell style={{width: "16%"}}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="statusFilter">Durum</InputLabel>
                                <Select
                                    labelId="statusFilter"
                                    value={statusFilter || ""}
                                    onChange={applyStatusFilter}
                                    label="Durumu"
                                    defaultValue=""
                                    endAdornment={
                                        statusFilter && (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClearStatus}>
                                                    <ClearIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }>
                                    {statusOptions.map((status) => (
                                        <MenuItem key={status} value={status}>
                                            {getPresentableLegalNoticeStatus(status)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                    <TableRow>
                        <TableCell>TEBLİGAT BAŞLIĞI</TableCell>
                        <TableCell>AÇIKLAMA</TableCell>
                        <TableCell>ATANAN KULLANICILAR</TableCell>
                        <TableCell>TEBLİĞ TARİHİ</TableCell>
                        <TableCell>SON TARİH</TableCell>
                        <TableCell>DURUMU</TableCell>
                        <TableCell>İŞLEMLER</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allPaginatedLegalNotices?.entity.map((notice) => (
                        <TableRow key={notice.id}>
                            <TableCell>{notice.title}</TableCell>
                            <TableCell>{notice.description}</TableCell>
                            <TableCell>
                                {notice.assignees.length > 0
                                    ? notice.assignees.map((user) => user.firstName + " " + user.lastName).join(', ')
                                    : 'Atama Yok'}
                            </TableCell>
                            <TableCell>{notice.arrivalDate
                                ? moment.unix(notice.arrivalDate).format("DD MMMM YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell>{notice.dueDate
                                ? moment.unix(notice.dueDate).format("DD MMMM YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell>{getPresentableLegalNoticeStatus(notice.status)}</TableCell>
                            <TableCell>
                                <NoticeAction
                                    notice={notice}
                                    onChangeStatus={openChangeStatus}
                                    onDelete={openDelete}/>
                            </TableCell>
                        </TableRow>
                    ))}
                    {loadLegalNoticesTaskStatus?.type !== AsyncTaskStatusType.Loading && allPaginatedLegalNotices?.numberOfElements === 0 && (
                        <TableRow>
                            <TableCell colSpan={7} style={{textAlign: "center"}}>
                                Tebligat bulunamadı.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {allPaginatedLegalNotices?.totalNumberPages != null && allPaginatedLegalNotices.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedLegalNotices.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
}
