import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {Delete, Edit} from "@mui/icons-material";
import {CourtHouseDTO, EditCourtHouseDTO} from "../api/dtos";

interface CourtHouseActionProps {
    courtHouse: CourtHouseDTO;
    onEdit: (courtHouse: CourtHouseDTO) => void;
    onDelete: (courtHouse: CourtHouseDTO) => void;
    handleEdit?: (updatedCourtHouse: EditCourtHouseDTO) => void;
}

const CourtHouseAction: React.FC<CourtHouseActionProps> = ({
                                                               courtHouse,
                                                               onEdit,
                                                               onDelete,
                                                               handleEdit,
                                                           }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    return (
        <>
            <IconButton
                aria-controls={open ? 'court-house-action-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                size='small'
                aria-label='settings'
                sx={{color: 'text.secondary'}}>
                <DotsVertical/>
            </IconButton>
            <Menu
                id="court-house-action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={() => handleMenuItemClick(() => onEdit(courtHouse))}>
                    <IconButton size='small' aria-label='edit' sx={{color: 'text.secondary'}}>
                        <Edit/>
                    </IconButton>
                    Düzenle
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(() => onDelete(courtHouse))}>
                    <IconButton size='small' aria-label='delete' sx={{color: 'text.secondary'}}>
                        <Delete/>
                    </IconButton>
                    Sil
                </MenuItem>
            </Menu>
        </>
    );
};

export default CourtHouseAction;
