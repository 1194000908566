import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {LegalCaseDTO, LegalCaseStatisticsDTO, PaginatedEntityList} from "../../api/dtos";

export interface LegalCaseState {
    createLegalCaseTaskStatus: AsyncTaskStatus | null;
    loadLegalCaseTaskStatus: AsyncTaskStatus | null;
    loadLegalCasesTaskStatus: AsyncTaskStatus | null;
    loadCompanyLegalCasesTaskStatus: AsyncTaskStatus | null;
    addFileToLegalCaseTaskStatus: AsyncTaskStatus | null;
    allPaginatedLegalCases: PaginatedEntityList<LegalCaseDTO> | null;
    allPaginatedCompanyLegalCases: PaginatedEntityList<LegalCaseDTO> | null;
    companyLegalCases: LegalCaseDTO[] | null;
    legalCase: LegalCaseDTO | null;
    statistics: LegalCaseStatisticsDTO | null;
    loadStatisticsTaskStatus: AsyncTaskStatus | null;
    allPaginatedCompanySpecificLegalCases: PaginatedEntityList<LegalCaseDTO> | null;
    updateLegalCaseStatusTaskStatus: AsyncTaskStatus | null;
    addNoteToLegalCaseTaskStatus: AsyncTaskStatus | null;
}

const initialState: LegalCaseState = {
    createLegalCaseTaskStatus: null,
    loadLegalCasesTaskStatus: null,
    loadLegalCaseTaskStatus: null,
    loadCompanyLegalCasesTaskStatus: null,
    allPaginatedLegalCases: null,
    allPaginatedCompanyLegalCases: null,
    allPaginatedCompanySpecificLegalCases: null,
    companyLegalCases: null,
    addFileToLegalCaseTaskStatus: null,
    legalCase: null,
    statistics: null,
    loadStatisticsTaskStatus: null,
    updateLegalCaseStatusTaskStatus: null,
    addNoteToLegalCaseTaskStatus: null
};

const legalCaseSlice = createSlice({
    name: 'legalCase',
    initialState,
    reducers: {
        setCreateLegalCaseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createLegalCaseTaskStatus = action.payload;
        },
        setLoadLegalCasesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadLegalCasesTaskStatus = action.payload;
        },
        setLoadCompanyLegalCasesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadCompanyLegalCasesTaskStatus = action.payload;
        },
        setLoadLegalCaseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadLegalCaseTaskStatus = action.payload;
        },
        setAllPaginatedLegalCases: (state, action: PayloadAction<PaginatedEntityList<LegalCaseDTO> | null>) => {
            state.allPaginatedLegalCases = action.payload;
        },
        setLegalCase: (state, action: PayloadAction<LegalCaseDTO | null>) => {
            state.legalCase = action.payload;
        },
        setAddFileToLegalCaseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.addFileToLegalCaseTaskStatus = action.payload;
        },
        setLoadStatisticsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadStatisticsTaskStatus = action.payload;
        },
        setStatistics: (state, action: PayloadAction<LegalCaseStatisticsDTO | null>) => {
            state.statistics = action.payload;
        },
        setAllPaginatedCompanySpecificLegalCases: (state, action: PayloadAction<PaginatedEntityList<LegalCaseDTO> | null>) => {
            state.allPaginatedCompanySpecificLegalCases = action.payload;
        },
        setUpdateLegalCaseStatusTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.updateLegalCaseStatusTaskStatus = action.payload;
        },
        setAddNoteToLegalCaseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.addNoteToLegalCaseTaskStatus = action.payload;
        }
    }
});

export const {
    setCreateLegalCaseTaskStatus,
    setAllPaginatedLegalCases,
    setLoadLegalCasesTaskStatus,
    setLoadCompanyLegalCasesTaskStatus,
    setLoadLegalCaseTaskStatus,
    setAddFileToLegalCaseTaskStatus,
    setLegalCase,
    setStatistics,
    setLoadStatisticsTaskStatus,
    setAllPaginatedCompanySpecificLegalCases,
    setUpdateLegalCaseStatusTaskStatus,
    setAddNoteToLegalCaseTaskStatus
} = legalCaseSlice.actions;

export default legalCaseSlice.reducer;
