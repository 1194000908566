import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Autocomplete,
    Box,
    Button,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import moment from "moment";
import * as XLSX from "xlsx-js-style";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {setAllPaginatedCompanySpecificLegalCases} from "../features/legal-case/legalCaseSlice";
import {AppState} from "../store";
import {CaseType, CompanyType, getPresentableCompanyType, getPresentableLegalCase} from "../api/dtos";
import UserLayout from "../components/UserLayout";
import {robotoRegularBase64} from "../shared/pdf-fonts";
import {getAllCompaniesAction} from "../api/settings";
import {getCompanyLegalCasesAction} from "../api/legalCase";
import {LitigantType} from "../api/litigantType";

export default function CompanyLegalCases() {
    const dispatch: any = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [selectedCompany, setSelectedCompany] = useState<any | null>(null);
    const emptyOption = " ";
    const [caseType, setCaseType] = useState<CaseType | " " | null>(emptyOption);
    const page = Number(searchParams.get('page') || "1");
    const companies = useSelector((state: AppState) => state.settings.allCompanies);
    const companyLegalCases = useSelector((state: AppState) => state.legalCase.allPaginatedCompanySpecificLegalCases);
    const branches = companies?.filter((company) => company.type === CompanyType.Branch) || [];
    const subsidiaries = companies?.filter((company) => company.type === CompanyType.Subsidiary) || [];
    const groupedOptions = [...branches, ...subsidiaries];
    const user = useSelector((state: AppState) => state.auth.userWithToken?.user);

    useEffect(() => {
        dispatch(getAllCompaniesAction());
    }, [dispatch]);

    useEffect(() => {
        if (selectedCompany) {
            dispatch(getCompanyLegalCasesAction({
                companyIdFilter: selectedCompany.id,
                typeFilter: caseType === emptyOption ? undefined : (caseType as CaseType | null) || undefined,
                responsibleIdFilter: user?.id || null,
                page: page
            }));
        }
    }, [selectedCompany, caseType, page, dispatch]);

    const handleCompanyChange = (_: any, newValue: any) => {
        if (newValue) {
            setSelectedCompany(newValue);
        } else {
            setSelectedCompany(null);
            dispatch(setAllPaginatedCompanySpecificLegalCases(null));
        }
    };

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const caseTypeLabel = "DAVALAR";
    const prosecutorsLabel = "DAVACI";
    const defendantsLabel = "DAVALI";
    const reportedEntitiesLabel = "İHBAR OLUNANLAR";
    const fileNumberLabel = "DOSYA NUMARASI";
    const caseDateLabel = "DAVA TARİHİ";

    const title = `ŞİRKET BAZLI ${caseTypeLabel}`;

    const formatLitigants = (litigants: any[] | undefined) => {
        return litigants?.map((litigant) => {
            switch (litigant.type as LitigantType) {
                case LitigantType.Company:
                    return litigant.company?.value;
                case LitigantType.Client:
                    return litigant.client?.value;
                case LitigantType.FreeText:
                    return litigant.name;
                default:
                    return "-";
            }
        }).join(', ') || "-";
    };

    const exportToExcel = () => {
        const headers = [[
            "KONU", prosecutorsLabel, defendantsLabel, reportedEntitiesLabel, fileNumberLabel, caseDateLabel, "DAVA TÜRÜ"
        ]];

        const worksheet = XLSX.utils.json_to_sheet(
            (companyLegalCases?.entity || []).map((legalCase) => {
                const result: any = {
                    "KONU": legalCase.name || "-",
                    [prosecutorsLabel]: formatLitigants(legalCase.prosecutors),
                    [defendantsLabel]: formatLitigants(legalCase.defendants),
                    [reportedEntitiesLabel]: formatLitigants(legalCase.reportedEntities),
                    [fileNumberLabel]: legalCase.fileNumber || "-",
                    [caseDateLabel]: legalCase.caseDate ? moment.unix(legalCase.caseDate).format('DD MMMM YYYY') : "-",
                    "DAVA TÜRÜ": getPresentableLegalCase(legalCase.type)
                };

                return result;
            })
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(worksheet, headers);

        const headerRow = headers[0];
        const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
        worksheet['!cols'] = columnWidths;
        headerRow.forEach((_, index) => {
            const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
            if (worksheet[cellRef]) {
                worksheet[cellRef].s = {
                    font: {bold: true},
                    fill: {
                        patternType: "solid",
                        fgColor: {rgb: "D3D3D3"}
                    }
                };
            }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, title);
        XLSX.writeFile(workbook, `şirket_bazlı_davalar_listesi.xlsx`);
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularBase64);
        doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
        doc.setFont('Roboto');
        doc.setFontSize(18);
        doc.text(title, 14, 20);

        const tableData = (companyLegalCases?.entity || []).map((legalCase) => [
            legalCase.name || "-",
            formatLitigants(legalCase.prosecutors),
            formatLitigants(legalCase.defendants),
            formatLitigants(legalCase.reportedEntities),
            legalCase.fileNumber || "-",
            legalCase.caseDate ? moment.unix(legalCase.caseDate).format('DD MMMM YYYY') : "-",
            getPresentableLegalCase(legalCase.type)
        ].filter(Boolean));

        const tableColumns = [
            "KONU", prosecutorsLabel, defendantsLabel, reportedEntitiesLabel, fileNumberLabel, caseDateLabel, "DAVA TÜRÜ"
        ];

        autoTable(doc, {
            head: [tableColumns],
            body: tableData,
            startY: 30,
            margin: {horizontal: 10},
            styles: {
                font: 'Roboto',
                cellPadding: 3,
                fontSize: 8,
                valign: 'middle',
                halign: 'left'
            },
            columnStyles: {
                0: {cellWidth: "auto"},
                1: {cellWidth: "auto"},
                2: {cellWidth: "auto"},
                3: {cellWidth: "auto"},
                4: {cellWidth: "auto"},
                5: {cellWidth: "auto"},
                6: {cellWidth: "auto"}
            }
        });

        doc.save(`şirket_bazlı_davalar_listesi.pdf`);
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <Typography variant="h4" component="h1" gutterBottom>
                    ŞİRKET BAZLI DAVALAR
                </Typography>
            </div>
            <Box>
                <Autocomplete
                    lang="tr"
                    value={selectedCompany}
                    options={groupedOptions}
                    groupBy={(option) => getPresentableCompanyType(option.type).toLocaleUpperCase("tr")}
                    getOptionLabel={(option) => option.name}
                    onChange={handleCompanyChange}
                    renderInput={(params) => <TextField {...params} placeholder="Seçiniz" variant="outlined"/>}
                    sx={{width: 300, mb: 3}}/>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            Excel'e Dışa Aktar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportToPDF}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            PDF'e Dışa Aktar
                        </Button>
                    </Box>
                </Box>
                <Table sx={{minWidth: 800}} aria-label="company-legal-cases-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Konu</TableCell>
                            <TableCell>{prosecutorsLabel}</TableCell>
                            <TableCell>{defendantsLabel}</TableCell>
                            <TableCell>{reportedEntitiesLabel}</TableCell>
                            <TableCell>{fileNumberLabel}</TableCell>
                            <TableCell>{caseDateLabel}</TableCell>
                            <TableCell>Dava Türü</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companyLegalCases?.entity.map((legalCase) => (
                            <TableRow key={legalCase.id}>
                                <TableCell>{legalCase.name || "-"}</TableCell>
                                <TableCell>{formatLitigants(legalCase.prosecutors)}</TableCell>
                                <TableCell>{formatLitigants(legalCase.defendants)}</TableCell>
                                <TableCell>{formatLitigants(legalCase.reportedEntities)}</TableCell>
                                <TableCell>{legalCase.fileNumber || "-"}</TableCell>
                                <TableCell>{legalCase.caseDate ? moment.unix(legalCase.caseDate).format('DD MMMM YYYY') : "-"}</TableCell>
                                <TableCell>{getPresentableLegalCase(legalCase.type)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {companyLegalCases?.totalNumberPages != null && companyLegalCases.totalNumberPages > 1 && (
                    <Box sx={{display: 'flex', justifyContent: 'center', mt: 3}}>
                        <Pagination
                            page={page}
                            count={companyLegalCases.totalNumberPages}
                            onChange={handleChangePage}
                            color="primary"/>
                    </Box>
                )}
            </Box>
        </UserLayout>
    );
}
