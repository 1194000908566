import {LitigantType} from "./litigantType";

export enum UserRole {
    Admin = 'Admin',
    Manager = 'Manager',
    Member = 'Member',
    Guest = 'Guest',
    Company = 'Company'
}

export const getPresentableUserRole = (userRole: UserRole) => {
    switch (userRole) {
        case UserRole.Admin:
            return "Sistem Yöneticisi";
        case UserRole.Guest:
            return "Ziyaretçi";
        case UserRole.Manager:
            return "Yönetici";
        case UserRole.Member:
            return "Üye";
        case UserRole.Company:
            return "Firma";
    }
}

export interface EditCourtDTO {
    name: string;
    type: CourtType;
    courtHouseId: number;
}

export enum CourtType {
    Law = 'Law',
    Investigation = 'Investigation',
    Criminal = 'Criminal',
    Executive = 'Executive'
}

export const getPresentableCourtType = (courtType: CourtType) => {
    switch (courtType) {
        case CourtType.Criminal:
            return "Ceza Mahkemesi";
        case CourtType.Executive:
            return "İcra Dairesi";
        case CourtType.Investigation:
            return "Savcılık";
        case CourtType.Law:
            return "Hukuk Mahkemesi";
    }
}

export enum LitigationType {
    Takip1 = 'Takip1',
    Takip2 = 'Takip2'
}

export enum CaseType {
    Law = 'Law',
    Investigation = 'Investigation',
    Criminal = 'Criminal',
    Executive = 'Executive'
}

export enum LegalCaseStatus {
    Open = 'Open',
    Closed = 'Closed',
    Execution = 'Execution',
    NonProsecution = 'NonProsecution'
}

export const getPresentableLegalCaseStatus = (status: LegalCaseStatus) => {
    switch (status) {
        case LegalCaseStatus.Open:
            return "Açık - Derdest";
        case LegalCaseStatus.Closed:
            return "Kapalı";
        case LegalCaseStatus.Execution:
            return "İnfaz";
        case LegalCaseStatus.NonProsecution:
            return "Takipsizlik";
    }
}

export interface AddUserDTO {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirmation: string;
    role: UserRole;
    associatedCompanyId: number | null;
}

export interface EditUserDTO {
    firstName: string;
    lastName: string;
    role: UserRole;
    associatedCompanyId: number | null
}

export interface AddEditCompanyDTO {
    name: string;
    type: CompanyType
}

export interface AddEditClientDTO {
    name: string;
}

export interface SettingsLookupDTO {
    id: number;
    value: string;
}

export interface AppUserLookupDTO {
    id: number;
    value: string;
}

export interface AddEditCourtDTO {
    name: string;
    type: CourtType;
    courtHouseId: number;
}

export interface AddEditCourtHouseDTO {
    name: string,
    cityPlate: number
}

export interface AddLitigantToCaseDTO {
    companyId: number | null;
    clientId: number | null;
    name: string | null;
    type: LitigantType;
}

export interface AddLegalCaseMetadata {
    file: File;
    metadata: AddLegalCaseDTO;
}

export interface AddLegalCaseDTO {
    name: string;
    prosecutorIds: AddLitigantToCaseDTO[];
    defendantIds: AddLitigantToCaseDTO[];
    reportedEntityIds: AddLitigantToCaseDTO[];
    courtId: number;
    type: CaseType;
    fileNumber: string;
    caseDate: number | null;
    investigationDate: string | null;
    litigationType: LitigationType | null;
    trialDates: number[];
    notes: string | null;
    relatedCaseIds: number[];
    appeal: string | null;
    responsibleIds: number[];
}

export enum TaskType {
    Free = 'Free',
    LegalCaseBound = 'LegalCaseBound'
}

export const getPresentableTaskType = (taskType: TaskType) => {
    switch (taskType) {
        case TaskType.Free:
            return 'Serbest';
        case TaskType.LegalCaseBound:
            return 'Dava Bağlı';
    }
}

export enum TaskPriority {
    Urgent = 'Urgent',
    High = 'High',
    Medium = 'Medium',
    Low = 'Low'
}

export const getPresentableTaskPriority = (taskPriority: TaskPriority) => {
    switch (taskPriority) {
        case TaskPriority.Urgent:
            return 'Acil';
        case TaskPriority.High:
            return 'Yüksek';
        case TaskPriority.Medium:
            return 'Orta';
        case TaskPriority.Low:
            return 'Düşük'
    }
}

export interface AddTaskDTO {
    type: TaskType;
    priority: TaskPriority;
    legalCaseId: number | null;
    assigneeIds: number[];
    title: string;
    description: string;
    dueDate: string | null;
    companyId: number | null;
}

export interface LitigantDTO {
    id: number;
    company: SettingsLookupDTO | null;
    client: SettingsLookupDTO | null;
    name: string | null;
    type: LitigantType
}

export interface CityDTO {
    plateId: number;
    name: string;
}

export interface EditCourtHouseDTO {
    name: string;
    cityId: number;
}

export interface CourtHouseDTO {
    id: number;
    name: string;
    city: CityDTO
}

export interface CourtDTO {
    id: number;
    name: string;
    courtHouse: CourtHouseDTO;
    type: CourtType;
}

export interface CaseFileInfoDTO {
    id: number;
    name: string;
    relativeDirPath: string;
    contentType: string;
    description: string;
    ready: boolean;
}

export interface MinimalLegalCaseDTO {
    id: number;
    name: string;
    type: CaseType;
}

export enum TaskStatus {
    Created = 'Created',
    InProgress = 'InProgress',
    OverDue = 'OverDue',
    WaitingForReply = 'WaitingForReply',
    Completed = 'Completed'
}

export interface EditTaskStatusDTO {
    newStatus: TaskStatus;
}

export const getPresentableTaskStatus = (status: TaskStatus) => {
    switch (status) {
        case TaskStatus.InProgress:
            return 'Devam Ediyor';
        case TaskStatus.Completed:
            return 'Tamamlandı';
        case TaskStatus.Created:
            return 'Oluşturuldu';
        case TaskStatus.OverDue:
            return 'Yanıt Bekleniyor';
        case TaskStatus.WaitingForReply:
            return 'Cevap İçin Bekleniyor';
    }
}

export interface UserInTaskDTO {
    id: number;
    firstName: string;
    lastName: string;
}

export interface TaskDTO {
    id: number;
    type: TaskType;
    title: string;
    description: string;
    status: TaskStatus;
    priority: TaskPriority;
    legalCase: MinimalLegalCaseDTO | null;
    assignees: UserInTaskDTO[];
    dueDate: number | null;
    createdAt: number | null;
}

export interface EditTaskDTO {
    title: string;
    description: string;
    priority: TaskPriority;
    assigneeIds: number[];
    dueDate: number | null;
}

export interface ExpenseDTO {
    id: number;
    name: string;
    currency: string;
    amount: any;
    relatedTask: TaskDTO | null;
    relatedLegalCase: MinimalLegalCaseDTO | null
}

export interface UserInLegalCaseDTO {
    id: number;
    firstName: string;
    lastName: string;
}

export enum LegalNoticeStatus {
    Created = 'Created',
    InProgress = 'InProgress',
    Completed = 'Completed'
}

export const getPresentableLegalNoticeStatus = (status: LegalNoticeStatus) => {
    switch (status) {
        case LegalNoticeStatus.Created:
            return "Oluşturuldu";
        case LegalNoticeStatus.InProgress:
            return "Devam Ediyor";
        case LegalNoticeStatus.Completed:
            return "Tamamlandı";
    }
}

export interface CreateLegalNoticeDTO {
    title: String;
    description: string;
    arrivalDate: string;
    dueDate: string;
    legalCaseId: number;
    assigneeIds: number[];
}

export interface LegalCaseInLegalNoticeDTO {
    id: number;
    name: string;
    type: CaseType;
}

export interface LegalNoticeDTO {
    id: number;
    title: string;
    description: string;
    status: LegalNoticeStatus;
    arrivalDate: number;
    dueDate: number;
    assignees: UserInTaskDTO[];
    legalCase: LegalCaseInLegalNoticeDTO;
}

export interface LegalCaseDTO {
    id: number;
    name: string;
    prosecutors: LitigantDTO[],
    defendants: LitigantDTO[],
    reportedEntities: LitigantDTO[],
    court: CourtDTO,
    type: CaseType;
    status: LegalCaseStatus;
    fileNumber: string;
    caseDate: number | null;
    investigationDate: number | null;
    litigationType: LitigationType | null;
    trialDates: number[];
    files: CaseFileInfoDTO[];
    notes: string | null;
    relatedCases: MinimalLegalCaseDTO[];
    appeal: string | null;
    responsibles: UserInLegalCaseDTO[];
    mainFile: CaseFileInfoDTO | null;
}

export const getPresentableLegalCase = (legalCaseType: CaseType) => {
    switch (legalCaseType) {
        case CaseType.Criminal:
            return "Ceza Davası";
        case CaseType.Executive:
            return "İcra Takibi";
        case CaseType.Investigation:
            return "Soruşturma";
        case CaseType.Law:
            return "Hukuk Davası";
    }
}

export interface PaginatedEntityList<T> {
    totalNumberPages: number;
    totalElements: number;
    numberOfElements: number;
    entity: T[];
}

export interface UserDTO {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    locked: boolean;
    deleted: boolean;
    role: UserRole;
    associatedCompany: CompanyDTO | null
}

export interface CompanyDTO {
    id: number;
    name: string;
    type: CompanyType;
}

export interface LegalCaseStatisticDTO {
    name: string;
    count: number;
}

export enum CompanyType {
    Branch = 'Branch',
    Subsidiary = 'Subsidiary'
}

export const getPresentableCompanyType = (type: CompanyType) => {
    switch (type) {
        case CompanyType.Branch:
            return "Şubeler";
        case CompanyType.Subsidiary:
            return "İştirakler";
    }
}

export interface LegalCaseStatisticsDTO {
    companyProsecutorStatistics: LegalCaseStatisticDTO[];
    companyProsecutorOpenStatistics: LegalCaseStatisticDTO[];
    companyDefendantStatistics: LegalCaseStatisticDTO[];
    companyDefendantOpenStatistics: LegalCaseStatisticDTO[];
    companyReportedEntityStatistics: LegalCaseStatisticDTO[];
    companyReportedEntityOpenStatistics: LegalCaseStatisticDTO[];
    statisticsByUser: LegalCaseStatisticDTO[];
    statisticsByStatus: LegalCaseStatisticDTO[];
    statisticsByUserOpen: LegalCaseStatisticDTO[];
}

export interface AddEditMailSettingsDTO {
    propMap: { [key: string]: string };
}

export interface PasswordResetDTO {
    oldPassword: string | null;
    password: string;
    passwordConfirmation: string;
}

export interface ContractDTO {
    id: number;
    type: ContractType;
    status: ContractStatus;
    relatedSide: string;
    signDate: number;
    validUntil?: number;
    relatedCompany: CompanyDTO;
}

export enum ContractType {
    Sports = 'Sports',
    Termination = 'Termination',
    Sponsor = 'Sponsor'
}

export const getPresenTableContractType = (contractType: ContractType) => {
    switch (contractType) {
        case ContractType.Sports:
            return 'Sporcu Sözleşmesi'
        case ContractType.Sponsor:
            return 'Sponsor'
        case ContractType.Termination:
            return 'Fesih'
    }
}

export enum ContractStatus {
    Signed = 'Signed',
    Expired = 'Expired'
}

export const getPresentableContractStatus = (contractStatus: ContractStatus) => {
    switch (contractStatus) {
        case ContractStatus.Signed:
            return 'Kayıtlı'
        case ContractStatus.Expired:
            return 'Tamamlanmış'
    }
}

export interface AddContractDTO {
    type: ContractType;
    status: ContractStatus;
    relatedSide: string;
    signDate: string;
    validUntil?: string;
    relatedCompanyId: number;
}

export interface AddNoteToLegalCaseDTO {
    note: string;
}
