import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {CreateUserDialog} from "./create-user/createUserDialog";
import {getUsersAction} from "../api/appUser";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Pagination} from "@mui/lab";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {getPresentableUserRole, UserDTO} from "../api/dtos";
import UserAction from "../actions/UserAction";
import {ResetPasswordDialog} from "./reset-password/resetPasswordDialog";
import {AppState} from "../store";
import {EditUserDialog} from "./edit-user/editUserDialog";
import * as XLSX from "xlsx-js-style";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {robotoRegularBase64} from "../shared/pdf-fonts";
import Button from "@mui/material/Button";

export default function Users() {
    const [activeUserForAction, setActiveUserForAction] = useState<UserDTO | null>(null);
    const [searchParams] = useSearchParams();

    const page = Number(searchParams.get('page') || "1");
    const dispatch: any = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
    useEffect(() => {
        dispatch(getUsersAction({page}));
    }, [dispatch, page]);

    const allPaginatedUsers = useSelector((state: AppState) => state.appUser.allPaginatedUsers);
    const handleCreateUserDialogOpen = () => setCreateUserDialogOpen(true);
    const handleCreateUserDialogClose = () => setCreateUserDialogOpen(false);

    const handleResetPasswordDialogOpen = () => setResetPasswordDialogOpen(true);
    const handleResetPasswordDialogClose = () => setResetPasswordDialogOpen(false);

    const handleEditUserDialogOpen = () => setEditUserDialogOpen(true);
    const handleEditUserDialogClose = () => setEditUserDialogOpen(false);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const onChangePassword = (user: UserDTO) => {
        setActiveUserForAction(user);
        handleResetPasswordDialogOpen();
    }

    const onEditUser = (user: UserDTO) => {
        setActiveUserForAction(user);
        handleEditUserDialogOpen();
    }

    const exportToExcel = () => {
        const headers = [['E-Posta', 'Adı', 'Soyadı', 'Rolü']];
        const worksheet = XLSX.utils.json_to_sheet(
            (allPaginatedUsers?.entity || []).map(user => ({
                "E-Posta": user.email,
                "Adı": user.firstName,
                "Soyadı": user.lastName,
                "Rolü": getPresentableUserRole(user.role)
            }))
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(worksheet, headers);
        const headerRow = headers[0];
        const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
        worksheet['!cols'] = columnWidths;
        headerRow.forEach((_, index) => {
            const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
            if (worksheet[cellRef]) {
                worksheet[cellRef].s = {
                    font: {bold: true},
                    fill: {
                        patternType: "solid",
                        fgColor: {rgb: "D3D3D3"}
                    }
                };
            }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "Kullanıcı Listesi");
        XLSX.writeFile(workbook, "kullanıcı_listesi.xlsx");
    };

    const exportToPDF = () => {
        const doc = new jsPDF();

        doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularBase64);
        doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
        doc.setFont('Roboto');

        doc.setFontSize(18);

        doc.text('Kullanıcı Listesi', 14, 20);

        const tableData = (allPaginatedUsers?.entity || []).map(user => [
            user.email,
            user.firstName,
            user.lastName,
            getPresentableUserRole(user.role)
        ]);

        const tableColumns = ['E-Posta', 'Adı', 'Soyadı', 'Rolü'];

        autoTable(doc, {
            head: [tableColumns],
            body: tableData,
            startY: 30,
            margin: {horizontal: 10},
            styles: {
                font: 'Roboto',
                cellPadding: 3,
                fontSize: 10,
                valign: 'middle',
                halign: 'left'
            }
        });

        doc.save('kullanici_listesi.pdf');
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>KULLANICILAR</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            Excel'e Dışa Aktar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportToPDF}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            PDF'e Dışa Aktar
                        </Button>
                    </Box>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginBottom: 5}}
                        onClick={handleCreateUserDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>

            {createUserDialogOpen && (
                <CreateUserDialog page={page} handleClose={handleCreateUserDialogClose}/>
            )}

            {resetPasswordDialogOpen && activeUserForAction && (
                <ResetPasswordDialog handleClose={handleResetPasswordDialogClose} user={activeUserForAction}/>
            )}

            {editUserDialogOpen && activeUserForAction && (
                <EditUserDialog page={page} handleClose={handleEditUserDialogClose} user={activeUserForAction}/>
            )}

            <Table sx={{minWidth: 800}} aria-label='criminal case table'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: "20%"}}>E-POSTA</TableCell>
                        <TableCell style={{width: "20%"}}>ADI</TableCell>
                        <TableCell style={{width: "20%"}}>SOYADI</TableCell>
                        <TableCell style={{width: "20%"}}>ROLÜ</TableCell>
                        <TableCell style={{width: "20%"}}>BAĞLI FİRMA</TableCell>
                        <TableCell style={{width: "20%"}}>İŞLEMLER</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allPaginatedUsers?.entity.map((client: UserDTO) => (
                        <TableRow key={client.id}>
                            <TableCell>{client.email}</TableCell>
                            <TableCell>{client.firstName}</TableCell>
                            <TableCell>{client.lastName}</TableCell>
                            <TableCell>{getPresentableUserRole(client.role)}</TableCell>
                            <TableCell>
                                {client.associatedCompany?.name || "-"}
                            </TableCell>
                            <TableCell>
                                <UserAction
                                    user={client}
                                    onEdit={onEditUser}
                                    onChangePassword={onChangePassword}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {allPaginatedUsers?.totalNumberPages != null && allPaginatedUsers.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedUsers.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
}
