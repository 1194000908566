import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {AsyncTaskStatusType} from "../shared/dtos";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {AppState} from "../store";
import {CompanyType} from "../api/dtos";

export const PrivateRoutes = () => {
    const location = useLocation();
    const theme = useTheme()
    const {userWithToken} = useSelector((state: AppState) => state.auth);
    const {loadUserDataTaskStatus} = useSelector((state: AppState) => state.settings);
    const associatedCompanyType = userWithToken?.user?.associatedCompany?.type;
    if (associatedCompanyType === CompanyType.Branch) {
        const allowableRoutes = [
            "/profile",
            "/gorevler",
            "/sozlesmeler"
        ]

        const dynamicRouteRegex = new RegExp(`^(${allowableRoutes.join("|")})(\\/[^/?#]+)?$`);

        const cleanPathname = location.pathname.split('?')[0].split('#')[0];

        if (
            allowableRoutes.includes(cleanPathname) ||
            dynamicRouteRegex.test(cleanPathname)
        ) {
            // TODO: it is left empty for now
        } else {
            return (<Navigate to="/gorevler"/>)
        }

    } else if (associatedCompanyType === CompanyType.Subsidiary) {
        const allowableRoutes = [
            "/profile",
            "/gorevler",
            "/sozlesmeler"
        ]

        const dynamicRouteRegex = new RegExp(`^(${allowableRoutes.join("|")})(\\/[^/?#]+)?$`);

        const cleanPathname = location.pathname.split('?')[0].split('#')[0];

        if (
            allowableRoutes.includes(cleanPathname) ||
            dynamicRouteRegex.test(cleanPathname)
        ) {
            // TODO: it is left empty for now
        } else {
            return (<Navigate to="gorevler"/>)
        }
    }


    if (loadUserDataTaskStatus?.type === AsyncTaskStatusType.Loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 3,
                        boxShadow: 3,
                        backgroundColor: theme.palette.primary.main
                    }}>
                    <CardContent sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CircularProgress color="secondary"/>
                        <Typography variant="h6" sx={{marginTop: 2, color: theme.palette.secondary.main}}>
                            Yükleniyor...
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        );
    }
    // TODO: user will be warned you have an network error page
    return userWithToken?.user
        ? <Outlet/>
        : <Navigate to="/logout" replace state={{from: location}}/>;
}
