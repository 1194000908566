import {createAsyncThunk} from "@reduxjs/toolkit";
import {AddContractDTO, ContractStatus, ContractType} from "./dtos";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {getAppToken} from "../shared/utils";
import {AppState} from "../store";
import axios from "axios";
import {DevRootURL, EndPoints} from "./EndPoints";
import {
    setAllPaginatedContracts,
    setCreateContractTaskStatus,
    setGetAllPaginatedContractsTaskStatus
} from "../features/contracts/contractSlice";

export const createContractAction = createAsyncThunk(
    'contract/createContract',
    async (dto: AddContractDTO, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setCreateContractTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.post(EndPoints.contract, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, fieldErrors, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateContractTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setCreateContractTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    fieldErrors: fieldErrors,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setCreateContractTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllPaginatedContractsAction = createAsyncThunk(
    'contract/getAllContracts',
    async ({
               relatedCompanyId,
               type,
               status,
               page
           }: {
        relatedCompanyId: number;
        type: ContractType | null;
        status: ContractStatus | null;
        page: number;
    }, {dispatch, getState, rejectWithValue}) => {
        dispatch(setGetAllPaginatedContractsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const params = new URLSearchParams({
                relatedCompanyId: relatedCompanyId.toString(),
                page: page.toString()
            });

            if (type != null) {
                params.append("type", type);
            }

            if (status != null) {
                params.append("status", status);
            }

            const response = await axios.get(EndPoints.contract + `/contracts-by-filter?${params.toString()}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setGetAllPaginatedContractsTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedContracts(resultValue));
                return resultValue;
            } else {
                dispatch(setGetAllPaginatedContractsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setGetAllPaginatedContractsTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
