import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Fab,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getAllPaginatedTasksAction} from "../../api/task";
import {AppState} from "../../store";
import {getPresentableTaskPriority, getPresentableTaskStatus, TaskPriority, TaskStatus} from "../../api/dtos";
import {setLoadLegalCaseTaskStatus} from "../../features/legal-case/legalCaseSlice";
import {CreateTaskDialog} from "../create-task/createTaskDialog";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export interface LegalCaseDetailsProps {
    id: number;
}

export const LegalCaseTask: React.FC<LegalCaseDetailsProps> = ({id}) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch: any = useDispatch();
    const location = useLocation();
    const allPaginatedTasks = useSelector((state: AppState) => state.task.allPaginatedTasks);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const page = Number(searchParams.get('page') || "1");
    const pageRef = useRef(page);
    const [textFilter, setTextFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState<TaskStatus | null>(null);
    const [priorityFilter, setPriorityFilter] = useState<TaskPriority | null>(null);

    useEffect(() => {
        dispatch(getAllPaginatedTasksAction({
            legalCaseIdFilter: id,
            userIdFilter: null,
            companyIdFilter: null,
            textFilter,
            priorityFilter,
            statusFilter,
            page
        }));

        return () => {
            dispatch(setLoadLegalCaseTaskStatus(null));
        };
    }, [id, dispatch, page, textFilter, priorityFilter, statusFilter]);

    useEffect(() => {
        if (pageRef.current !== page) {
            dispatch(getAllPaginatedTasksAction({
                legalCaseIdFilter: id,
                userIdFilter: null,
                companyIdFilter: null,
                textFilter,
                priorityFilter,
                statusFilter,
                page
            }));
        }
        pageRef.current = page;
    }, [page]);

    const handleAddTask = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => setPopupOpen(false);

    const handleCreateTask = () => {
        if (page === 1) {
            dispatch(getAllPaginatedTasksAction({
                legalCaseIdFilter: id,
                userIdFilter: null,
                companyIdFilter: null,
                textFilter,
                priorityFilter,
                statusFilter,
                page: 1
            }));
        } else {
            navigate("?page=1");
        }
    };

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const applyStatusFilter = (e: { target: { value: string; }; }) => {
        const status = e.target.value === "" ? null : (e.target.value as TaskStatus)
        setStatusFilter(status);
    };

    const applyTextFilter = (e: { target: { value: any; }; }) => {
        const inputTextSearch = e.target.value;
        setTextFilter(inputTextSearch)
    }

    const applyPriorityFilter = (e: { target: { value: string; }; }) => {
        const priority = e.target.value === "" ? null : (e.target.value as TaskPriority)
        setPriorityFilter(priority);
    };

    const statusOptions = Object.values(TaskStatus);
    const priorityOptions = Object.values(TaskPriority);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                }}>
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{marginLeft: "auto", marginBottom: 7}}
                    onClick={handleAddTask}>
                    <AddIcon/>
                </Fab>
            </Box>
            <Paper elevation={3} sx={{p: 3}}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="task table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        margin="dense"
                                        label="Görev Adı"
                                        variant="outlined"
                                        size="small"
                                        onChange={applyTextFilter}/>
                                </TableCell>
                                <TableCell/>
                                <TableCell>
                                    <FormControl variant="outlined" size="small" fullWidth>
                                        <InputLabel id="status-label">Öncelik</InputLabel>
                                        <Select
                                            labelId="priority-label"
                                            label="Öncelik"
                                            onChange={applyPriorityFilter}
                                            defaultValue="">
                                            {priorityOptions.map((priority) => (
                                                <MenuItem key={priority} value={priority}>
                                                    {getPresentableTaskPriority(priority)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell/>
                                <TableCell>
                                    <FormControl variant="outlined" size="small" fullWidth>
                                        <InputLabel id="status-label">Durumu</InputLabel>
                                        <Select
                                            labelId="status-label"
                                            label="Durumu"
                                            onChange={applyStatusFilter}
                                            defaultValue="">
                                            {statusOptions.map((status) => (
                                                <MenuItem key={status} value={status}>
                                                    {getPresentableTaskStatus(status)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Görev Adı</TableCell>
                                <TableCell>Açıklama</TableCell>
                                <TableCell>Öncelik</TableCell>
                                <TableCell>Atanan Kullanıcılar</TableCell>
                                <TableCell>Durumu</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allPaginatedTasks?.entity.map((task) => (
                                <TableRow key={task.id}>
                                    <TableCell>{task.title}</TableCell>
                                    <TableCell>{task.description}</TableCell>
                                    <TableCell>{getPresentableTaskPriority(task.priority)}</TableCell>
                                    <TableCell>
                                        {task.assignees.length > 0
                                            ? task.assignees.map((user: any) => user.firstName + " " + user.lastName).join(', ')
                                            : 'Atama Yok'}
                                    </TableCell>
                                    <TableCell>{getPresentableTaskStatus(task.status)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {allPaginatedTasks?.totalNumberPages != null && allPaginatedTasks.totalNumberPages > 1 && (
                    <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                        <Pagination
                            page={page}
                            count={allPaginatedTasks.totalNumberPages}
                            defaultPage={1}
                            onChange={handleChangePage}/>
                    </div>
                )}
                {isPopupOpen && (
                    <CreateTaskDialog
                        legalCaseId={id}
                        handleClose={handleClosePopup}
                        handleCreateTask={handleCreateTask}/>
                )}
            </Paper>
        </>
    );
};
