import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CustomDialog from "../../components/CustomDialog";
import {Button, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
    AddContractDTO,
    ContractStatus,
    ContractType,
    getPresentableContractStatus,
    getPresenTableContractType
} from "../../api/dtos";
import {createContractAction} from "../../api/contract";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {AppState} from "../../store";

export interface CreateContractDialogProps {
    onClose: () => void;
    onContractAdded: () => void;
}

export const CreateContractDialog: React.FC<CreateContractDialogProps> = ({onClose, onContractAdded}) => {
    const dispatch: any = useDispatch();
    const createContractTaskStatus = useSelector((state: AppState) => state.contract.createContractTaskStatus);
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const [contractType, setContractType] = useState<ContractType | null>(null);
    const [contractStatus, setContractStatus] = useState<ContractStatus | null>(null);
    const [relatedSide, setRelatedSide] = useState<string>('');
    const [signDate, setSignDate] = useState<Date | null>(null);
    const [validUntil, setValidUntil] = useState<Date | null>(null);
    const [errors, setErrors] = useState<FieldErrors>({});
    const [isValidUntilDisabled, setIsValidUntilDisabled] = useState(true);
    const isSubmitDisabled = !contractType || !contractStatus || !relatedSide || !signDate;

    useEffect(() => {
        setIsValidUntilDisabled(!signDate);
    }, [signDate]);

    const handleSubmit = async () => {
        if (!signDate || !currentUser?.associatedCompany || !contractType || !contractStatus) return;

        const newContract: AddContractDTO = {
            type: contractType,
            status: contractStatus,
            relatedSide,
            signDate: signDate.toISOString(),
            validUntil: validUntil ? validUntil.toISOString() : undefined,
            relatedCompanyId: currentUser.associatedCompany.id
        };

        try {
            await dispatch(createContractAction(newContract));
            onContractAdded();
            onClose();
        } catch (error) {
            console.error("Sözleşme eklerken hata oluştu:", error);
        }
    };

    useEffect(() => {
        if (createContractTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            createContractTaskStatus.fieldErrors.forEach(({field, message}) => {
                updatedErrors[field] = message;
            });
            setErrors(updatedErrors);
        }
    }, [createContractTaskStatus]);

    return (
        <CustomDialog
            open={true}
            onClose={onClose}>
            <DialogTitle>Sözleşme Ekle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="contract-type-label">Sözleşme Türü <span>*</span></InputLabel>
                    <Select
                        labelId="contract-type-label"
                        id="contract-type"
                        name="contract-type"
                        value={contractType}
                        onChange={(e) => setContractType(e.target.value as ContractType)}
                        label="Sözleşme Türü *"
                        fullWidth
                        required>
                        <MenuItem value={ContractType.Sports}>
                            {getPresenTableContractType(ContractType.Sports)}
                        </MenuItem>
                        <MenuItem value={ContractType.Termination}>
                            {getPresenTableContractType(ContractType.Termination)}
                        </MenuItem>
                        <MenuItem value={ContractType.Sponsor}>
                            {getPresenTableContractType(ContractType.Sponsor)}
                        </MenuItem>
                    </Select>
                    {errors.type && <ErrorText message={errors.type}/>}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="contract-status-label">Sözleşme Durumu <span>*</span></InputLabel>
                    <Select
                        label="Sözleşme Durumu *"
                        labelId="contract-status-label"
                        value={contractStatus}
                        onChange={(e) => setContractStatus(e.target.value as ContractStatus)}
                        fullWidth
                        required>
                        <MenuItem value={ContractStatus.Signed}>
                            {getPresentableContractStatus(ContractStatus.Signed)}
                        </MenuItem>
                        <MenuItem value={ContractStatus.Expired}>
                            {getPresentableContractStatus(ContractStatus.Expired)}
                        </MenuItem>
                    </Select>
                    {errors.status && (
                        <ErrorText message={errors.status}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <TextField
                        label="İlgili Taraf"
                        value={relatedSide}
                        onChange={(e) => setRelatedSide(e.target.value)}
                        fullWidth
                        required/>
                    {errors.relatedSide && (
                        <ErrorText message={errors.relatedSide}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                        <DateTimePicker
                            label="İmzalanma Tarihi"
                            value={signDate}
                            onChange={(newDate) => setSignDate(newDate)}
                            //@ts-ignore
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    fullWidth/>
                            )}/>
                    </LocalizationProvider>
                    {errors.signDate && (
                        <ErrorText message={errors.signDate}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                        <DateTimePicker
                            label="Geçerlilik Tarihi"
                            value={validUntil}
                            onChange={(newDate) => setValidUntil(newDate)}
                            disabled={isValidUntilDisabled}
                            minDate={signDate || undefined}
                            //@ts-ignore
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth/>
                            )}/>
                    </LocalizationProvider>
                    {errors.validUntil && (
                        <ErrorText message={errors.validUntil}/>
                    )}
                </FormControl>

                {createContractTaskStatus?.type === AsyncTaskStatusType.Error && createContractTaskStatus.errorMessage && (
                    <ErrorText type="form" message={createContractTaskStatus.errorMessage}/>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button onClick={handleSubmit} color="primary" disabled={isSubmitDisabled}>
                    Kaydet
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
