import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {createTaskAction} from "../../api/task";
import {AddTaskDTO, TaskPriority, TaskType} from "../../api/dtos";
import {TextFieldVariants} from "@mui/material/TextField/TextField";
import ErrorText from "../../components/ErrorText";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import {AppState} from "../../store";
import {getAllUsersAction, getUsersAction} from "../../api/appUser";
import {setCreateTaskTaskStatus} from "../../features/task/taskSlice";
import CustomDialog from "../../components/CustomDialog";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";
import {setAllPaginatedUsers, setAllUsersLookup} from "../../features/app-user/appUserSlice";

interface CreateTaskDialogProps {
    handleClose: () => void;
    legalCaseId?: number;
    handleCreateTask: () => void;
}

const fieldVariant = "outlined";

export const CreateTaskDialog: React.FC<CreateTaskDialogProps> = ({
                                                                      handleClose,
                                                                      legalCaseId,
                                                                      handleCreateTask
                                                                  }) => {
    // @TODO: use form
    const dispatch: any = useDispatch();
    const allPaginatedUsers = useSelector((state: AppState) => state.appUser.allPaginatedUsers);
    const allUsersLookup = useSelector((state: AppState) => state.appUser.allUsersLookup);
    const createTaskTaskStatus = useSelector((state: AppState) => state.task.createTaskTaskStatus);
    const [title, setTitle] = useState("");
    const [priority, setPriority] = useState<TaskPriority | null>(null);
    const [description, setDescription] = useState("");
    const [assigneeIds, setAssigneeIds] = useState<number[]>([]);
    const [dueDate, setDueDate] = useState<string | null>(null);
    const [dateError, setDateError] = useState<boolean>(false);
    const [errors, setErrors] = useState<FieldErrors>({});
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const useAssociatedCompanyId = currentUser?.associatedCompany?.id;

    useEffect(() => {
        if (useAssociatedCompanyId) {
            // @TODO: add getAllUsersByFilter if all companies not loaded
            dispatch(getUsersAction({companyIdFilter: currentUser?.associatedCompany?.id, page: 1}));
        } else {
            dispatch(getAllUsersAction());
        }
        return () => {
            dispatch(setCreateTaskTaskStatus(null));
            dispatch(setAllPaginatedUsers(null));
            dispatch(setAllUsersLookup(null));
        }
    }, [dispatch]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!dueDate) {
            setDateError(true);
            return;
        }

        if (priority) {
            const formJson: AddTaskDTO = {
                title,
                type: legalCaseId ? TaskType.LegalCaseBound : TaskType.Free,
                priority,
                description,
                legalCaseId: legalCaseId || null,
                assigneeIds,
                dueDate,
                companyId: currentUser?.associatedCompany?.id || null,
            };

            const result = await dispatch(createTaskAction(formJson));
            if (result.type === "task/createTask/fulfilled") {
                handleClose();
                handleCreateTask();
            }
        }
    };

    useEffect(() => {
        if (createTaskTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            createTaskTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [createTaskTaskStatus]);

    const handleClear = () => setPriority(null);

    const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);

    const handleChangePriority = (e: SelectChangeEvent<TaskPriority | null>) => setPriority(e.target.value as TaskPriority)

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Görev Ekle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="title"
                        name="title"
                        label="Görev Adı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={title}
                        onChange={handleChangeTitle}/>
                    {errors.title && (
                        <ErrorText message={errors.title}/>
                    )}
                </FormControl>

                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="description"
                        name="description"
                        label="Açıklama"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}/>
                    {errors.description && <ErrorText message={errors.description}/>}
                </FormControl>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                        <DateTimePicker
                            label="Hedeflenen Tarih"
                            value={dueDate ? new Date(dueDate) : null}
                            onChange={(newDate) => {
                                if (newDate && !isNaN(newDate.getTime())) {
                                    setDueDate(newDate.toISOString());
                                    setDateError(false);
                                } else {
                                    setDueDate(null);
                                    setDateError(true);
                                }
                            }}
                            //@ts-ignore
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    fullWidth
                                    required
                                    error={dateError}/>
                            )}/>
                    </LocalizationProvider>
                    {dateError && (
                        <ErrorText message={dateError ? 'Son tarih seçilmesi zorunludur' : ''}/>
                    )}
                    {errors.dueDate && (
                        <ErrorText message={errors.dueDate}/>
                    )}
                </FormControl>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="assignee-ids-label">Atananlar <span>*</span></InputLabel>
                    <Select
                        required
                        labelId="assignee-ids-label"
                        id="assignee-ids"
                        name="assigneeIds"
                        label="Atananlar"
                        fullWidth
                        multiple={true}
                        variant={fieldVariant as TextFieldVariants}
                        value={assigneeIds}
                        onChange={(event: any) => setAssigneeIds(event.target.value)}>
                        {useAssociatedCompanyId
                            ? allPaginatedUsers?.entity.map((paginatedUser) => (
                                <MenuItem key={paginatedUser.id} value={paginatedUser.id}>
                                    {paginatedUser.firstName} {paginatedUser.lastName}
                                </MenuItem>
                            ))
                            : allUsersLookup?.map((assigneeLookup: any) => (
                                <MenuItem key={assigneeLookup.id} value={assigneeLookup.id}>
                                    {assigneeLookup.value}
                                </MenuItem>
                            ))}
                    </Select>
                    {errors.assigneeIds && (
                        <ErrorText message={errors.assigneeIds}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="task-priority-label">Öncelik <span>*</span></InputLabel>
                    <Select
                        labelId="task-priority-label"
                        required
                        id="priority"
                        name="priority"
                        label="Öncelik"
                        fullWidth
                        value={priority}
                        onChange={handleChangePriority}
                        endAdornment={
                            priority && (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClear}>
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }>
                        <MenuItem value={TaskPriority.Urgent}>Acil</MenuItem>
                        <MenuItem value={TaskPriority.High}>Yüksek</MenuItem>
                        <MenuItem value={TaskPriority.Medium}>Orta</MenuItem>
                        <MenuItem value={TaskPriority.Low}>Düşük</MenuItem>
                    </Select>
                    {errors.priority && (
                        <ErrorText message={errors.priority}/>
                    )}
                </FormControl>

                {createTaskTaskStatus?.type === AsyncTaskStatusType.Error && createTaskTaskStatus.errorMessage && (
                    <ErrorText type="form" message={createTaskTaskStatus.errorMessage}/>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Oluştur</Button>
            </DialogActions>
        </CustomDialog>
    );
};
