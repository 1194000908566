import axios from "axios";
import {EndPoints} from "./EndPoints";
import {AddUserDTO, EditUserDTO, PasswordResetDTO} from "./dtos";
import {getAppToken, getAppTokenOld} from "../shared/utils";
import {setLoadUsersTaskStatus} from "../features/settings/settingsSlice";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {
    setAllPaginatedUsers,
    setAllUsersLookup,
    setCreateUserTaskStatus,
    setEditUserTaskStatus,
    setGetAllUsersLookupTaskStatus,
    setResetOwnPasswordTaskStatus,
    setResetPasswordTaskStatus
} from "../features/app-user/appUserSlice";
import {AppState} from "../store";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const createUserAction = createAsyncThunk(
    'user/createUser',
    async (dto: AddUserDTO, {dispatch, getState, rejectWithValue, fulfillWithValue}: any) => {
        dispatch(setCreateUserTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.post(`${EndPoints.user}/`, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, fieldErrors} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateUserTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else if (code === ResponseDTO.CodeEnum.DuplicateObject) {
                dispatch(setCreateUserTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: 'Bu kullanıcı mevcut'
                }));
                return rejectWithValue('Bu kullanıcı mevcut');
            } else {
                dispatch(setCreateUserTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message || 'Bir hata oluştu. Lütfen tekrar deneyin',
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message || 'Bir hata oluştu. Lütfen tekrar deneyin');
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setCreateUserTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const editUserAction = createAsyncThunk(
    'user/editUser',
    async ({id, dto}: { id: number, dto: EditUserDTO }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }: any) => {
        dispatch(setEditUserTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.put(`${EndPoints.user}/${id}`, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, fieldErrors} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setEditUserTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setEditUserTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message || 'Bir hata oluştu. Lütfen tekrar deneyin',
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message || 'Bir hata oluştu. Lütfen tekrar deneyin');
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setEditUserTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteUserAction = createAsyncThunk(
    'user/deleteUser',
    async (userId: number, {rejectWithValue}) => {
        try {
            const token = await getAppTokenOld(null);
            if (!token) {
                throw new Error('Token is missing');
            }

            await axios.delete(`${EndPoints.user}/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            return true;
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                console.error('Error deleting user:', error.message);
                return rejectWithValue(error.message);
            } else {
                console.error('Unexpected error:', error);
                return rejectWithValue('Unexpected error occurred');
            }
        }
    }
);

export const getUsersAction = createAsyncThunk(
    'user/getUsers',
    async ({emailSearchText, nameSearchText, roleFilter, companyIdFilter, page = 1}: {
        emailSearchText?: string, nameSearchText?: string, roleFilter?: string, companyIdFilter?: number, page: number
    }, {dispatch, getState, rejectWithValue}) => {
        dispatch(setLoadUsersTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const params = new URLSearchParams({
                page: page.toString()
            });
            if (emailSearchText) {
                params.append("emailSearchText", emailSearchText)
            }
            if (nameSearchText) {
                params.append("nameSearchText", nameSearchText)
            }
            if (roleFilter) {
                params.append("roleFilter", roleFilter)
            }
            if (companyIdFilter) {
                params.append("companyIdFilter", companyIdFilter.toString())
            }

            const response = await axios.get(`${EndPoints.users}?${params.toString()}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadUsersTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedUsers(resultValue));
                return resultValue;
            } else {
                dispatch(setLoadUsersTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadUsersTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllUsersAction = createAsyncThunk(
    'user/getAllUsers',
    async (_, {dispatch, getState, rejectWithValue}) => {
        dispatch(setGetAllUsersLookupTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.get(EndPoints.user + "/allUsers", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setGetAllUsersLookupTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllUsersLookup(resultValue));
                return resultValue;
            } else {
                dispatch(setGetAllUsersLookupTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setGetAllUsersLookupTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const resetPasswordAction = createAsyncThunk(
    'user/resetPassword',
    async ({id, dto}: { id: number, dto: PasswordResetDTO }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }: any) => {
        dispatch(setResetPasswordTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.put(`${EndPoints.user}/${id}/reset-password`, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setResetPasswordTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setResetPasswordTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message || 'Bir hata oluştu. Lütfen tekrar deneyin',
                    errorDetails,
                }));
                return rejectWithValue(message || 'Bir hata oluştu. Lütfen tekrar deneyin');
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setResetPasswordTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const resetOwnPasswordAction = createAsyncThunk(
    'user/resetOwnPassword',
    async (dto: PasswordResetDTO, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }: any) => {
        dispatch(setResetOwnPasswordTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.put(`${EndPoints.user}/reset-password`, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, fieldErrors} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setResetOwnPasswordTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setResetOwnPasswordTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message || 'Bir hata oluştu. Lütfen tekrar deneyin',
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message || 'Bir hata oluştu. Lütfen tekrar deneyin');
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setResetOwnPasswordTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
