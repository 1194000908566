// ** React Imports
import {Fragment, SyntheticEvent, useState} from 'react'

// ** Next Import
// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import {styled} from '@mui/material/styles'
import LogoutVariant from 'mdi-material-ui/LogoutVariant'
import AccountOutline from 'mdi-material-ui/AccountOutline'
import {useNavigate} from "react-router-dom";

const BadgeContentSpan = styled('span')(({theme}) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = () => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const navigate = useNavigate();

    const handleDropdownOpen = (event: SyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickDropdownButton = (url?: string) => {
        if (url) {
            navigate(url);
        }
        setAnchorEl(null);
    };

    const styles = {
        py: 2,
        px: 4,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        textDecoration: 'none',
        '& svg': {
            fontSize: '1.375rem',
            color: 'text.secondary'
        }
    };

    return (
        <Fragment>
            <Badge
                overlap='circular'
                onClick={handleDropdownOpen}
                sx={{ml: 2, cursor: 'pointer'}}
                badgeContent={<BadgeContentSpan/>}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Avatar
                    alt='John Doe'
                    onClick={handleDropdownOpen}
                    sx={{width: 40, height: 40}}
                    src='/avatar.png'/>
            </Badge>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClickDropdownButton()}
                sx={{'& .MuiMenu-paper': {width: 230, marginTop: 4}}}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}>
                <MenuItem sx={{p: 0}} onClick={() => handleClickDropdownButton('/profile')}>
                    <Box sx={styles}>
                        <AccountOutline sx={{marginRight: 2}}/>
                        Profilim
                    </Box>
                </MenuItem>
                <MenuItem sx={{py: 2}} onClick={() => handleClickDropdownButton('/logout')}>
                    <LogoutVariant sx={{marginRight: 2, fontSize: '1.375rem', color: 'text.secondary'}}/>
                    Çıkış
                </MenuItem>
            </Menu>
        </Fragment>
    );
};

export default UserDropdown
